import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import moment from "moment";
import api from "../../common/api";
import {Typography} from "@material-ui/core";
import {useHistory} from "react-router";
import {useStores} from "../../common/store";
import {CommonCodeValue, isCommonCode, UserType} from "../../common/types";
import queryString from 'query-string';
import {addFilter, HistoryPage, isDate, isEmpty, TableHeadSort} from "./HistoryCommon";

/**** 사용자 관련 이력(등록, 수정, 삭제) 페이지:
 * 나의 이력 조회, 선택한 사용자의 이력 조회, 모든 사용자의 이력 조회
 * @param pageType : string can be "myHistory"(나의 이력), "all"(모든 사용자의 이력) or "undefined"(선택한 사용자의 이력)
 * ****/
export default function UserHistoryPage(props) {

    const {t} = useTranslation();
    const history = useHistory();
    const {ds} = useStores();
    const querySearch = props.params === undefined ? queryString.parse(props.location.search) : queryString.parse(props.params.location.search);

    const [valueList, setValueList] = useState([]);
    const pageType = props.pageType;
    const [dateRange, setDateRange] = useState(null);

    const [category, setCategory] = useState(querySearch.category ? querySearch.category : 'ALL');
    const [clientName, setClientName] = useState(querySearch.clientKey ? querySearch.clientKey : 'ALL');
    const [valueName, setValueName] = useState(querySearch.name ? querySearch.name : 'ALL');
    const [eventType, setEventType] = useState(querySearch.eventType ? querySearch.eventType : 'ALL');

    const [clientNameSearch, setClientNameSearch] = useState(querySearch.clientName ? querySearch.clientName : '');
    const [changeValue, setChangeValue] = useState(querySearch.changeValue ? querySearch.changeValue : '');
    const [actionUser, setActionUser] = useState(querySearch.actionUserKey ? querySearch.actionUserKey : '');
    const [eventUser, setEventUser] = useState(querySearch.eventUser ? querySearch.eventUser : '');

    const [filters, setFilters] = useState([])
    const [page, setPage] = useState(querySearch.page ? parseInt(querySearch.page) : 0);
    const [pageSize, setPageSize] = useState(querySearch.size ? parseInt(querySearch.size)  : 5);
    const [sort, setSort] = useState(querySearch.sort ? querySearch.sort  : 'REG_DT,DESC');

    let today = moment(new Date()).format('YYYY-MM-DD');
    const todayStart = today + " 00:00";
    const todayEnd = today + " 23:59";
    const [minDt, setMinDt] = useState(null);
    const [maxDt, setMaxDt] = useState(null);
    const [startDt, setStartDt] = useState(querySearch.startDt ? querySearch.startDt : todayStart);
    const [endDt, setEndDt] = useState(querySearch.endDt ? querySearch.endDt : todayEnd);
    const [startErr, setStartErr] = useState(false);
    const [endErr, setEndErr] = useState(false);
    const [resetUpt, setResetUpt] = useState(false);

    const [response, setResponse] = useState(null);

    const query = useRef({
        category:       isEmpty(category),
        clientName:     isEmpty(clientNameSearch),
        clientKey:      isEmpty(clientName),
        eventType:      isEmpty(eventType),
        name:           isEmpty(valueName),
        eventUser:      isEmpty(eventUser),
        changeValue:    isEmpty(changeValue),
        actionUserKey:  isEmpty(actionUser),
        startDt:        isEmpty(startDt),
        endDt:          isEmpty(endDt)
    })

    useEffect(() => {
        api.getHistoryValueList()
            .then((res) => {
                setValueList(res.data.categories);
                setDateRange(res.data.dateRange);
                if (minDt === null) setMinDt(moment(today).subtract((res.data.dateRange - 1), 'd').format('YYYY-MM-DD'))
            })
            .catch((err) => {
                ds.showErrorAlert(api.getErrMsg(err.rtCode));
            })
    }, []);

    const clientArray = [];
    if (ds.user.accessibleClient !== undefined && ds.user.accessibleClient !== null) {
        if (clientArray.length === 0) {
            let clientIndex = 0;
            for (let key in ds.user.accessibleClient) {
                if (UserType.isManager(ds.user.accessibleClient[key].userType)) {
                    clientArray[clientIndex++] = {
                        value: ds.user.accessibleClient[key].clientKey,
                        text: ds.user.accessibleClient[key].clientName
                    };
                }
            }
        }
    }

    const [clientPlaceholder, setClientPlaceholder] = useState(querySearch.clientName ? querySearch.clientName : querySearch.clientKey ? _.find(clientArray, {value: querySearch.clientKey}).text : '');

    const eventTypeArray = [
        {value: 'CMMACT001', text: t(CommonCodeValue.translateKey + '.CMMACT001')},
        {value: 'CMMACT002', text: t(CommonCodeValue.translateKey + '.CMMACT002')},
        {value: 'CMMACT003', text: t(CommonCodeValue.translateKey + '.CMMACT003'), disabled: valueName !== 'ALL' || changeValue !== ''}
    ]

    function isDisabledByType(allowedType) {
        return category !== 'ALL' && category !== allowedType;
    }

    const valueNameArray = [];
    if (valueNameArray.length === 0 && valueList.length > 0) {
        valueList.find(item => item.category === 'CMMHSC004').valueNames?.map((i) => {
            valueNameArray.push({value: i, text: t('EventHistory.ValueName.' + i), disabled: isDisabledByType('CMMHSC004')})
        });
        valueList.find(item => item.category === 'CMMHSC007').valueNames?.map((i) => {
            valueNameArray.push({value: i, text: t('EventHistory.ValueName.' + i), disabled: isDisabledByType('CMMHSC007')})
        });
        valueList.find(item => item.category === 'CMMHSC008').valueNames?.map((i) => {
            valueNameArray.push({value: i, text: t('EventHistory.ValueName.' + i), disabled: isDisabledByType('CMMHSC008')})
        });
    }

    const categoryArray = [
        {value: 'CMMHSC004', text: t(CommonCodeValue.translateKey + '.CMMHSC004'), disabled: isDisabledByValue('CMMHSC004')},
        {value: 'CMMHSC007', text: t(CommonCodeValue.translateKey + '.CMMHSC007'), disabled: isDisabledByValue('CMMHSC007') || clientName !== 'ALL' || clientNameSearch !== ''},
        {value: 'CMMHSC008', text: t(CommonCodeValue.translateKey + '.CMMHSC008'), disabled: isDisabledByValue('CMMHSC008') || clientName !== 'ALL' || clientNameSearch !== ''}
    ]

    function isDisabledByValue(allowedType) {
        if (valueName === 'ALL') return false;
        let selectedItem = null;
        valueList.map(item => { if (item.valueNames?.find(v => v === valueName) !== undefined) selectedItem = item })
        return selectedItem?.category !== allowedType
    }

    const selectOptionArray = [
        {label: 'EventHistory.Table.Category', items: categoryArray, value: category, onChange: (value) => setCategory(value)},
        {label: 'EventHistory.Table.EventType', items: eventTypeArray, value: eventType, onChange: (value) => setEventType(value)},
        {label: 'EventHistory.Table.ValueName', items: valueNameArray, value: valueName, onChange: (value) => setValueName(value), disabled: eventType === 'CMMACT003'}
    ]

    const searchOptionArray = [
        {label: 'EventHistory.Table.ChangeValue', value: changeValue, onChange: (value) => setChangeValue(value), disabled: eventType === 'CMMACT003'},
        {label: 'EventHistory.ActionUserKey', value: actionUser, onChange: (value) => setActionUser(value)}
    ]

    if (pageType === 'all' && searchOptionArray.length === 3) {
        searchOptionArray.splice(1,0, {label: 'EventHistory.Table.UserKey', value: eventUser, onChange: (value) => setEventUser(value)})
    }

    const fetchData = () => {
        if (pageType === 'myHistory') {
            api.getMyEventHistory(query.current, page, pageSize, sort)
                .then((res) => {
                    setResponse(res);
                })
                .catch((err) => {
                    ds.showErrorAlert(api.getErrMsg(err.rtCode));
                })
        } else if (pageType === 'all') {
            api.getAllUserHistory(query.current, page, pageSize, sort)
                .then((res) => {
                    setResponse(res);
                })
                .catch((err) => {
                    ds.showErrorAlert(api.getErrMsg(err.rtCode));
                })
        } else {
            api.getUserEventHistory(props.params.clientKey, props.params.userKey, query.current, page, pageSize, sort)
                .then((res) => {
                    setResponse(res);
                })
                .catch((err) => {
                    ds.showErrorAlert(api.getErrMsg(err.rtCode));
                })
        }
    }

    const onSearch = () => {

        query.current = {
            category:       isEmpty(category),
            clientName:     isEmpty(clientNameSearch),
            clientKey:      isEmpty(clientName),
            eventType:      isEmpty(eventType),
            name:           isEmpty(valueName),
            changeValue:    isEmpty(changeValue),
            eventUser:      isEmpty(eventUser),
            actionUserKey:  isEmpty(actionUser),
            startDt:        isEmpty(startDt),
            endDt:          isEmpty(endDt)
        }

        if (startDt !== null && endDt !== null)  page !== 0 ? setPage(0) : fetchData();
        if (startDt === null) setStartErr(true);
        if (endDt === null) setEndErr(true);
        addFilter(startDt, endDt, deleteDates, clientPlaceholder, deleteClientName, selectOptionArray, searchOptionArray, setFilters);
        if(resetUpt) setResetUpt(false);
        if (pageType === 'myHistory' || pageType === 'all') {
            history.push({
                search: "?" + api.encodeParams({...query.current, page: page, size: pageSize, sort: sort}, true)
            })
        } else {
            history.push({
                search: "?" + api.encodeParams({clientKey: props.params.clientKey, userKey: props.params.userKey, ...query.current, page: page, size: pageSize, sort: sort }, true)
            })
        }
    }

    const onDeleteFilter = (idx, option) => {
        if (pageType !== 'myHistory' && pageType !== 'all' && option.onChange.name === 'deleteClientName') {
            return;
        }
        option.items ? option.onChange('ALL') : option.onChange('');
        let arr = _.cloneDeep(filters);
        arr.splice(idx, 1);
        setFilters(arr);
    }

    function resetDates() {
        setStartDt(todayStart);
        setEndDt(todayEnd);
        setMinDt(moment(today).subtract((dateRange - 1), 'd').format('YYYY-MM-DD'));
        setMaxDt(null);
    }

    function deleteDates() {
        setStartDt(null); setEndDt(null); setMinDt(null); setMaxDt(null)
    }

    const resetAll = () => {
        selectOptionArray.map((option) => option.onChange('ALL'));
        searchOptionArray.map((option) => option.onChange(''));
        resetDates();
        deleteClientName();
        setResetUpt(true)
    }

    useEffect(() => {
        if (dateRange !== null) {
            if (query.current.startDt !== null && query.current.endDt !== null) fetchData();
            if (minDt === null) setMinDt(moment(today).subtract((dateRange - 1), 'd').format('YYYY-MM-DD'))
        }
    }, [page, pageSize, sort, dateRange]);

    useEffect(() => {
        if (valueNameArray.length > 0) addFilter(startDt, endDt, deleteDates, clientPlaceholder, deleteClientName, selectOptionArray, searchOptionArray, setFilters);
    }, [valueList]);

    useEffect(() => {
        if (resetUpt) onSearch()
    }, [resetUpt]);

    const rowClickEvent = (row) => (event) => {
        if (pageType === 'myHistory') {
            history.push({
                pathname: '/mypage/event/detail',
                search: "?" + api.encodeParams({seq: row[body[0].length-1].content, category: row[1].code}),
                state: api.encodeParams({historyLabel: "EventHistory.UserHistory"})
            })
        } else {
            history.push({
                pathname: '/admin/event/detail',
                search: "?" + api.encodeParams({seq: row[body[0].length-1].content, category: row[1].code}),
                state: api.encodeParams({historyLabel: "EventHistory.UserHistory", historyUrl: "/admin/events/userHistory"})
            })
        }
    }

    const head = [
        [
            {content: ''},
            {content: t("EventHistory.Table.Category")},
            pageType !== undefined && {content: t("EventHistory.Table.Client")},
            pageType === 'all' && {content: t("EventHistory.Table.UserKey")},
            {content: t("EventHistory.Table.EventType")},
            query.current.eventType !== 'CMMACT003' && {content: t("EventHistory.Table.ValueName")},
            query.current.eventType !== 'CMMACT003' && {content: t("EventHistory.Table.ChangeValue")},
            {content: t("EventHistory.ActionUserKey")},
            {content: t("EventHistory.Table.IP")},
            {content: <TableHeadSort sortType={sort} setSortType={setSort}/> }
        ]
    ];

    const body = [];
    if (response !== null && response.data !== undefined) {

        let num = response.page.totalElements - (response.page.currentPage * pageSize);
        response.data.map((data, idx) => {
            let category = data.category ? t(CommonCodeValue.translateKey + '.' + data.category) : '-';
            let clientName = data.clientKey && clientArray.find(x => x.value === data.clientKey) ? clientArray.find(x => x.value === data.clientKey)?.text : '-';
            let eventType = data.eventType ? t(CommonCodeValue.translateKey + '.' + data.eventType) : '-';

            let valueName = data.name ? data.name : '-';
            if (valueNameArray.find(x => x.value === data.name) !== undefined) valueName = valueNameArray.find(x => x.value === data.name).text;

            let changeValue = data.changeValue ? <Typography dangerouslySetInnerHTML={{__html: data.changeValue}}/> : '-';
            if (data.changeValue && isCommonCode(data.changeValue)) changeValue = data.changeValue + "\n(" + t(CommonCodeValue.translateKey + '.' + data.changeValue) + ")";
            if (data.changeValue && isDate(data.changeValue)) changeValue = moment(data.changeValue).format('YYYY-MM-DD HH:mm:ss');

            let userKey = data.userKey ? data.userKey : '-';
            let actionUserKey = data.actionUserKey ? data.actionUserKey : '-';
            let actionIP = data.actionIp ? data.actionIp : '-';
            let timestamp = data.regDt ? moment(data.regDt).utcOffset(8).format('YYYY-MM-DD HH:mm:ss')  : '-';

            body[idx] = [
                {id: 'index', content: num--},
                {id: 'type', content: category, code: data.category},
                pageType !== undefined && {id: 'client', content: clientName},
                pageType === 'all' && {id: 'userKey', content: userKey},
                {id: 'eventType', content: eventType},
                query.current.eventType !== 'CMMACT003' && {id: 'valueName', content: valueName},
                query.current.eventType !== 'CMMACT003' && {id: 'changeValue', content: changeValue},
                {id: 'actionUserKey', content: actionUserKey},
                {id: 'ip', content: actionIP},
                {id: 'timestamp', content: timestamp},
                {id: 'seq', content: data.seq, client: clientName}
            ];

        });

        if (filters.findIndex(item => item.value === t('CommonCode.CMMHSC007')) !== -1
            || filters.findIndex(item => item.value === t('CommonCode.CMMHSC008')) !== -1) {
            body.map(item => item.splice(item.findIndex(i => i.id === 'client'),1))
            let idx = head[0].findIndex(item => item.content === t("EventHistory.Table.Client"));
            if (idx !== -1) head[0].splice(idx, 1)
        }
    }

    const pageChangeEvent = (event, pageIndex) => {
        if (pageType === 'myHistory' || pageType === 'all') {
            history.push({ search: "?" + api.encodeParams({...query.current, page: pageIndex - 1, size: pageSize, sort: sort}, true) })
        } else {
            history.push({ search: "?" + api.encodeParams({clientKey: props.params.clientKey, userKey: props.params.userKey, ...query.current, page: pageIndex - 1, size: pageSize, sort: sort }, true) })
        }
        setPage(pageIndex - 1);
    };

    const onSubmitDate = (type, value) => {
        if (type === 'start') {
            setStartDt(value);
            setMaxDt(moment(value).add((dateRange - 1), 'd').format('YYYY-MM-DD'))
            if (startErr) setStartErr(false);
        }
        if (type === 'end') {
            setEndDt(value);
            setMinDt(moment(value).subtract((dateRange - 1), 'd').format('YYYY-MM-DD'))
            if (endErr) setEndErr(false);
        }
    }

    const onChangePageSize = (value) => {
        if (pageType === 'myHistory' || pageType === 'all') {
            history.push({ search: "?" + api.encodeParams({...query.current, page: page, size: value, sort: sort}, true) })
        } else {
            history.push({ search: "?" + api.encodeParams({clientKey: props.params.clientKey, userKey: props.params.userKey, ...query.current, page: page, size: value, sort: sort }, true) })
        }
        setPageSize(value);
    }

    const onChangeClientName = (value) => {
        setClientName('ALL');
        setClientNameSearch(value);
        setClientPlaceholder(value);
    }

    const onSelectClientName = (value, text) => {
        setClientNameSearch('');
        setClientName(value);
        setClientPlaceholder(text);
    }

    function deleteClientName() {
        if (pageType === 'myHistory' || pageType === 'all') {
            setClientName('ALL');
            setClientNameSearch('');
            setClientPlaceholder('')
        }
    }

    return (
        <HistoryPage
            selectOptionArray={selectOptionArray}
            searchOptionArray={searchOptionArray}
            clientSearch={pageType !== undefined && {clientPlaceholder, clientArray, onChangeClientName, onSelectClientName}}
            filters={filters}
            onSearch={onSearch}
            onReset={resetAll}
            onDeleteFilter={onDeleteFilter}
            startDt={startDt}
            endDt={endDt}
            startErr={startErr}
            endErr={endErr}
            minDt={minDt}
            maxDt={maxDt}
            onSubmitDate={onSubmitDate}
            table={{
                data: response,
                head: head,
                body: body,
                page: page,
                pageSize: pageSize,
                pageChangeEvent: pageChangeEvent,
                rowClickEvent: rowClickEvent,
                setPageSize: onChangePageSize
            }}
        />
    )
}