import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import React, {useEffect, useState} from "react";
import {Redirect} from "react-router-dom";
import moment from "moment";
import {KeyboardDatePicker} from '@material-ui/pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import FormControl from "@material-ui/core/FormControl";
import {TextField} from "@material-ui/core";
import api from "../../common/api";
import DataTable from "../../components/DataTable";
import {CommonCodeValue, PatchType, UserType} from "../../common/types";
import SelectBox from "../../components/SelectBox";
import Box from "@material-ui/core/Box";
import queryString from 'query-string';
import DateRangeIcon from "@mui/icons-material/DateRange";
import {useStores} from "../../common/store";


const useStyles = makeStyles((theme) => ({
    pageContent: {
        margin: '40px 170px 60px 170px',
        padding: '35px 40px',
        backgroundColor: '#FFF',
        borderRadius: 6,
        [theme.breakpoints.between('xs', 'md')]: {
            margin: 20
        }
    },
    conditionDiv: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: 8,
        alignItems: 'stretch',
        marginBottom: "16px",
    },
    selectBox: {
        ...theme.select,
        minWidth: 200
    },
    inputBox: {
        minWidth: 200,
        height: 40,
        '& .MuiInputBase-root': {
            height: 40,
            '& fieldset': {
                height: 40,
                top: 0,
                '& legend, legend span': {
                    display: 'none'
                }
            },
            '& input::placeholder': {
                color: '#565568',
                opacity: 1
            }
        },
        '& .MuiOutlinedInput-input': {
            padding: '5px 15px',
            height: 30,
            fontSize: '0.75rem'
        },
        '& .MuiInputAdornment-root': {
            '& > button': {
                padding: 0,
                color: '#8D96AB',
                '& svg': {
                    fontSize: '20px'
                }
            }
        },
        '&:hover .MuiInputAdornment-root > button': {
            color: theme.palette.primary.main
        }
    },
    keywordSearch: {
        minWidth: 200,
        maxHeight: 40,
        backgroundColor : 'transparent',
        '& input':{
            fontFamily: theme.typography.body2.fontFamily,
            fontWeight: theme.typography.body2.fontWeight,
            fontSize: theme.typography.body2.fontSize,
        }
    },
    dateField: {
        "& .MuiInputBase-root": {
            height: '40px'
        },
        "& .MuiOutlinedInput-input": {
            paddingLeft: 20,
            fontFamily: ['Gmarket Sans', 'Noto Sans KR', 'sans-serif'],
            fontSize: 12,
            fontWeight: 500,
        },
        "& .MuiInputAdornment-root": {
            marginRight: 15
        },
        "& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #3C39CB",
        },
        "& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "2px solid #3C39CB",
        },
    },
    button: {
        justifyContent: 'center',
        minWidth: 140,
        paddingTop: 12,
        paddingBottom: 12,
        marginLeft: 16,
    },
}));

const tableStyle = makeStyles(() => ({
    headRow: {
        '& > :nth-child(1)': {
            minWidth: 60,
            width: 80
        },
        '& > :nth-child(2)': {
            minWidth: 240,
        },
        '& > :nth-child(3)': {
            minWidth: 110,
            width: 130
        },
        '& > :nth-child(4)': {
            minWidth: 60,
            width: 60
        },
        '& > :nth-child(5)': {
            minWidth: 100,
            width: 200
        },
        '& > :nth-child(6), :nth-child(7)': {
            minWidth: 60,
            width: 180
        }
    }
}));

export default function PatchNoteListPage(props) {

    const pageSize = 6;
    const {location, setSearch, setCreate} = props;
    const classes = useStyles();
    const {t} = useTranslation();
    const [response, setResponse] = useState(null);

    const query = queryString.parse(location.search);
    let keyword = query['keyword'] !== undefined ? query['keyword'] : null;
    let page = query['page'] ? Number(query['page']) : 1;

    const [type, setType] = useState(query['type'] ?? 'ALL');
    const [version, setVersion] = useState(query['version'] ?? '');
    const [regUser, setRegUser] = useState(query['regUser'] ?? '');
    const [postDate, setPostDate] = useState(query['regDate'] ?? null);
    const [deployDate, setDeployDate] = useState(query['deployDt'] ?? null);
    const [clientKey, setClientKey] = useState(query['clientKey'] ?? 'ALL');
    const [clickEvent, setClickEvent] = useState(location.state ? location.state.clickEvent : null);

    let searchParams = {
        keyword: keyword,
        clientKey: clientKey !== 'ALL' ? clientKey : null,
        type: type !== 'ALL' ? type : null,
        version: version !== '' ? version : null,
        regUser: regUser !== '' ? regUser : null,
        regDate: postDate ?? null,
        deployDt: deployDate ?? null,
        page: page
    }

    const changedSearchKeywordEvent = (value) => {
        keyword = value;
    }

    const changedSearchDateEvent = (type) => (date, value) => {
        if (type === "regDt") {
            setPostDate(value);
        } else if (type === "deployDt") {
            setDeployDate(value);
        }
    }

    const onChangeRegUser = ({target}) => {
        setRegUser(target.value);
    }

    const onChangeVersion = ({target}) => {
        setVersion(target.value);
    }

    const onSelectType = ({target}) => {
        setType(target.value);
    }

    const onSelectClient = ({target}) => {
        setClientKey(target.value);
    }

    const fetchData = (page = 1) => {
        searchParams = {
            keyword: keyword,
            clientKey: clientKey !== 'ALL' ? clientKey : null,
            type: type !== 'ALL' ? type : null,
            version: version !== '' ? version : null,
            regUser: regUser !== '' ? regUser : null,
            regDate: postDate ?? null,
            deployDt: deployDate ?? null,
            page: page
        }
        props.history.push('/patches?' + api.encodeParams(searchParams, true));

        api.getPatchNoteList(searchParams, page - 1, pageSize)
            .then(data => {
                setResponse(data);
            })
            .catch(err => {
                return err;
            });
    }

    useEffect(() => {
        fetchData();
    }, [type, version, regUser, postDate, deployDate, clientKey]);

    useEffect(() => {
        if (setSearch) {
            setSearch(
                {
                    value: keyword,
                    placeholder: t("PatchNotePage.SearchPlaceHolder"),
                    doQuery: fetchData,
                    onChange: changedSearchKeywordEvent
                }
            );
        }
        if (setCreate) {
            setCreate(
                {
                    description: t("PatchNotePage.NewPatchNote"),
                    dialogTitle: t('PatchNote.CreateDialog.Title')
                }
            );
        }
    }, [])

    const rowClickEvent = (row) => () => {
        if( row[8] !== undefined ) {
            setClickEvent({event: 'detail', seq: row[8]?.content, patchTitle: row[1]?.content});
        }
    }

    if (clickEvent !== null) {
        const eventType = clickEvent.event;
        if (eventType === 'detail') {
            return (
                <Redirect push to={
                    {
                        pathname: '/patches/detail',
                        search: "?" + api.encodeParams({seq: clickEvent.seq}),
                    }
                }/>
            );
        }
    }

    return (
        <Box className={classes.pageContent}>
            <Box display='flex' flexDirection='column'>
                <ConditionPanel type={type}
                                clientKey={clientKey}
                                version={version}
                                regUser={regUser}
                                postDate={postDate}
                                deployDate={deployDate}
                                onSelectType={onSelectType}
                                onSelectDate={changedSearchDateEvent}
                                onChangeRegUser={onChangeRegUser}
                                onChangeVersion={onChangeVersion}
                                onSelectClient={onSelectClient}
                />
                <ResultTable response={response}
                             changePage={fetchData}
                             currentPage={response !== null ? response.page.currentPage + 1 : 1}
                             totalPage={response !== null ? response.page?.totalPages : 0}
                             pageSize={pageSize}
                             rowClickEvent={rowClickEvent}
                />
            </Box>
        </Box>
    );
}

function ResultTable({response, changePage, rowClickEvent, currentPage, totalPage, pageSize}) {
    const tableStyles = tableStyle();
    const {t} = useTranslation();
    const body = [];

    const head = [
        [
            {content: ''},
            {align: 'left', content: t('PatchNotePage.PatchNote')},
            {content: t('PatchNote.Client')},
            {content: t('PatchNotePage.PatchNoteType')},
            {content: t('PatchNotePage.PatchNoteVersion')},
            {content: t('PatchNotePage.PatchNoteUser')},
            {content: t("PatchNote.Deployment")},
            {content: t('PatchNotePage.PatchNoteRegDt')}
        ]
    ];

    if (response !== null) {
        let index = response.page.totalElements - ( response.page.currentPage * pageSize );

        response.data.map((data, idx) => {
            let patchTitle = data.title ? data.title : '-';
            let patchType = data.patchType ? t(CommonCodeValue.translateKey + '.' + data.patchType) : '-';
            let version = data.version ? data.version : '-';
            let author = data.regUserName ? data.regUserName : '-';
            let deployDt = data.deployDt !== undefined ? moment(data.deployDt).format("YYYY-MM-DD HH:mm") : '-';
            let regDt = data.regDt !== undefined ? moment(data.regDt).format("YYYY-MM-DD") : '-';
            let seq = data.seq !== undefined ? data.seq : "-";
            let clientName = data.clientName ? data.clientName: '-' ;
            let clientKey = data.clientKey ? data.clientKey: '-' ;

            body[idx] = [
                {id: 'index', content: index--},
                {id: 'patchTitle', align: 'left', content: patchTitle},
                {id: 'clientName', content: clientName},
                {id: 'patchType', align: 'center', content: patchType},
                {id: 'version', align: 'center', content: version},
                {id: 'author', content: author},
                {id: 'deployDt', align: 'center', content: deployDt},
                {id: 'regDt', align: 'center', content: regDt},
                {id: 'seq', content: seq},
                {id: 'clientKey', content: clientKey}
            ];
        });
    }

    const pageChangeEvent = (event, pageIndex) => {
        changePage(pageIndex);
    };

    return (
        <DataTable style={tableStyles}
                   headContent={head}
                   bodyContent={body}
                   currentPage={currentPage}
                   totalPage={totalPage}
                   emptyText={t('Error.NoData')}
                   onChangePageEvent={pageChangeEvent}
                   oncClickRowEvent={rowClickEvent}
        />
    );
}


function ConditionPanel({ type, clientKey, version, postDate, deployDate, onSelectType, onSelectClient, onSelectDate, onChangeVersion, onChangeRegUser, regUser}) {

    const classes = useStyles()
    const {t} = useTranslation();
    const {ds} = useStores();

    const typeArray = [];
    if (typeArray.length === 0) {
        Object.keys(PatchType).map((key, index) => {
            typeArray[index] = {
                key: key,
                value: PatchType[key],
                text: t(CommonCodeValue.translateKey + '.' + PatchType[key])
            };
        });
    }

    const clientArray = [];
    if (clientArray.length === 0) {
        let clientIndex = 0;
        for( let key in ds.user.accessibleClient ) {
            if ( UserType.isManager( ds.user.accessibleClient[key].userType ) ) {
                clientArray[clientIndex++] = {value: ds.user.accessibleClient[key].clientKey, text: ds.user.accessibleClient[key].clientName};
            }
        }
    }

    return (
        <div className={classes.conditionDiv}>
            <SelectBox className={classes.selectBox}
                       title={t('PatchNote.Client')}
                       value={clientKey}
                       contents={clientArray}
                       onChange={onSelectClient}
                       addAll={true}
            />
            <SelectBox className={classes.selectBox}
                       title={t("PatchNote.Type")}
                       value={type}
                       contents={typeArray}
                       onChange={onSelectType}
                       addAll={true}
            />
            <FormControl className={classes.inputBox}>
                <TextField
                    className={classes.keywordSearch}
                    placeholder={t("PatchNote.Version")}
                    value={version}
                    onChange={onChangeVersion}
                    variant='outlined'
                    minRows={1}
                />
            </FormControl>
            <FormControl className={classes.inputBox}>
                <TextField
                    className={classes.keywordSearch}
                    placeholder={t("PatchNote.RegUser")}
                    value={regUser}
                    onChange={onChangeRegUser}
                    variant='outlined'
                    minRows={1}
                />
            </FormControl>
            <FilterDateBox className={classes.inputBox}
                           keyword={postDate}
                           title={t("PatchNote.PostDate")}
                           changedSearchKeywordEvent={onSelectDate('regDt')}
                           disableFuture={true}
            />
            <FilterDateBox className={classes.inputBox}
                           keyword={deployDate}
                           title={t("PatchNote.DeploymentDate")}
                           changedSearchKeywordEvent={onSelectDate('deployDt')}
                           disableFuture={false}
            />
        </div>
    );
}

function FilterDateBox({className, keyword, changedSearchKeywordEvent, title, disableFuture}) {

    return (
        <FormControl className={className}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    disableToolbar
                    disableFuture={disableFuture}
                    autoOk
                    clearable
                    orientation={"landscape"}
                    variant="dialog"
                    inputVariant="outlined"
                    format="yyyy-MM-dd"
                    onChange={changedSearchKeywordEvent}
                    value={keyword}
                    placeholder={title}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    showTodayButton={true}
                    keyboardIcon={<DateRangeIcon/>}
                />
            </MuiPickersUtilsProvider>
        </FormControl>
    );
}