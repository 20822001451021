import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useHistory} from 'react-router';
import queryString from 'query-string';
import _ from "lodash";
import {useTranslation} from "react-i18next";
import MobxReactForm from "mobx-react-form";
import dvr from "mobx-react-form/lib/validators/DVR";
import validatorjs from "validatorjs";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import moment from "moment";
import api from "../../common/api";
import {useStores} from "../../common/store";
import {clientType, CommonCodeValue, ErrCode, PatchType, User} from "../../common/types";
import InputTexts from "../../components/InputTexts";
import LabelText from "../../components/LabelText";
import TextEditor from "../../components/TextEditor";
import ProgressButton, {LoginBackGround} from "../../components/ProgressButton";
import DateRangeIcon from "@mui/icons-material/DateRange";
import ConfirmDialog from "../../components/ConfirmDialog";
import SelectBox from "../../components/SelectBox";
import DateTimeDialog from "../../components/DateTimeDialog";
import AccessClientSelectBox from "../../components/AccessClientSelectBox";


const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        display: 'flex',
        margin: '40px 170px 60px 170px',
        [theme.breakpoints.between('xs', 'md')]: {
            padding: 20,
        },
        justifyContent: 'space-around',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        }
    },
    root2: {
        flexGrow: 1,
        flexBasis: 0,
        borderRadius: 10,
        padding: '50px 40px',
        backgroundColor: '#FFF'
    },
    title: {
        color: theme.palette.text.primary,
        "& > *": {
            color: theme.palette.text.title,
        }
    },
    grayButton: {
        background: theme.buttons.btn1.background.default,
        color: theme.buttons.btn1.color,
        '&:hover': {
            background: theme.buttons.btn1.background.hover,
        }
    },
    headerContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        flexFlow: 'wrap',
        '& > div': {
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            [theme.breakpoints.between('xs', 'sm')]: {
                flexDirection:'column'
            },
            '& > dl': {
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(1),
                [theme.breakpoints.between('xs', 'sm')]: {
                    flexDirection:'column',
                    alignItems: 'initial',
                },
                '& > dt': {
                    minWidth: 100,
                    color: theme.palette.text.gray,
                    [theme.breakpoints.between('xs', 'sm')]: {
                        marginBottom:theme.spacing(1),
                    },
                },
                '& > dd': {
                    flexGrow: 1,
                    minWidth: 150,
                    marginLeft: theme.spacing(2),
                    marginRight: theme.spacing(2),
                    [theme.breakpoints.between('xs', 'sm')]: {
                        margin:0,
                    }
                }
            }
        }
    },
    Divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    bodyContainer: {
        position:'relative',
        minHeight: 250,
        display: 'flex',
        flex: 1,
        flexDirection: "column",
        "& > *": {
            color: theme.palette.text.primary,
        }
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
        '& > span': {
            ...theme.typography.h5,
            color: theme.palette.text.primary,
        }
    },
    buttonBox: {
        marginTop: theme.spacing(2),
        textAlign: 'right',
        '& > *': {
            marginLeft: theme.spacing(1),
        },
    },
    selectBox: {
        ...theme.select,
        minWidth: 200
    },
    inputBox: {
        '& .MuiInputBase-input, .Mui-disabled': {
            color: '#5C5C62',
            fontSize: '0.688rem',
            fontFamily: ['Gmarket Sans', 'Noto Sans KR', 'sans-serif']
        },
        '& .Mui-disabled': {
            '& input': {
                paddingLeft: 0
            },
            '& button': {
                display: 'none'
            },
            '&:hover fieldset': {
                border: 'none'
            }
        },
        minWidth: 200,
        height: 40,
        '& .MuiInputBase-root': {
            height: 40,
            '& fieldset': {
                height: 40,
                top: 0,
                '& legend, legend span': {
                    display: 'none'
                }
            },
            '& input::placeholder': {
                color: '#565568',
                opacity: 1
            }
        },
        '& .MuiOutlinedInput-input': {
            padding: '5px 15px',
            height: 30,
            fontSize: '0.75rem'
        },
        '& .MuiInputAdornment-root': {
            '& > button': {
                padding: 0,
                color: '#8D96AB',
                '& svg': {
                    fontSize: '20px'
                }
            }
        },
        '&:hover .MuiInputAdornment-root > button': {
            color: theme.palette.primary.main
        }
    },
    dateSelectBtn: {
        height: 40,
        minWidth: 250,
        maxWidth: '100%',
        width: 'auto',
        border: '1px solid #DBDADA',
        borderRadius: 4,
        justifyContent: 'flex-start',
        padding: '8px 15px',
        transitionDuration: 0,
        '& .MuiButton-label': {
            color: '#565568',
            fontSize: 12,
            width: '100%',
            lineHeight: '20px'
        },
        '& .MuiButton-endIcon': {
            marginLeft: 'auto',
            color: '#8D96AB',
            '& > svg': {
                width: 20,
                height: 20
            }
        },
        '&:hover': {
            backgroundColor: 'transparent',
            border: '1px solid #3C39CB',
            '& .MuiButton-label': {
                color: '#222',
            },
            '& .MuiButton-endIcon': {
                color: '#3C39CB'
            },
        }
    }
}));

export default function PatchDetailPage(props) {

    const classes = useStyles();
    const {location} = props;
    const {t} = useTranslation();
    const {ds} = useStores();
    const history = useHistory();
    const querySearch = queryString.parse(location.search);
    const clientKey = querySearch.clientKey;
    const clientName = querySearch.clientName;
    const seq = querySearch.seq;

    const patchDetail = useRef({});
    const cloneData = useRef({});

    const [response, setResponse] = useState([]);
    const [selectedType, setSelectedType] = useState(PatchType.Android);
    const [isEditable, setIsEditable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [saveDialog, setSaveDialog] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [clientNameValue, setClientNameValue] = useState(patchDetail.current.clientName);
    const [clientKeyValue, setClientKeyValue] = useState(patchDetail.current.clientKey);


    const toggleClickHandler = (value) => {
        setIsEditable(value);
    }

    const loadingHandler = (value) => {
        setLoading(value);
    }

    useEffect(() => {
        if (seq === undefined) {
            alert(t("Error.WRONG_APPROACH"));
            history.push('/patches');
        }

        api.getPatchNoteDetail(seq)
            .then(data => {
                patchDetail.current = data.data;
                cloneData.current = data.data;
                patchDetail.current.deployDt = moment(data.data.deployDt).format("yyyy-MM-DD HH:mm:ss");
                cloneData.current.deployDt = moment(data.data.deployDt).format("yyyy-MM-DD HH:mm:ss");
                setClientNameValue(data.data.clientName);
                setClientKeyValue(data.data.clientKey);
                setSelectedType(patchDetail.current.patchType)
                setResponse(data.data);
            })
            .catch(err => {
                ds.showErrorAlert(api.getErrMsg(err.rtCode));
                console.log("err => ", err);
                return err;
            });
    }, []);


    const formId = "patchNoteForm";
    const form = new MobxReactForm({
        fields: [
            {name: 'patchType', value: patchDetail.current.patchType, rules: 'required', label: t('PatchNote.Type')},
            {name: 'version', value: patchDetail.current.version, rules: 'required', label: t("PatchNote.Version")},
            {name: 'deployDt', value: patchDetail.current.deployDt, rules: 'required', label: t("PatchNote.DeployDate")},
            {name: 'clientKey', value: patchDetail.current.clientKey, rules: 'required', label: t("PatchNote.Client")},
            {
                name: 'title',
                value: patchDetail.current.title,
                rules: 'required',
                label: t("PatchNote.Title"),
            },
            {
                name: 'content',
                value: patchDetail.current.content,
                rules: 'required',
                label: t("PatchNote.Content"),
            },
        ]
    }, {
        plugins: {
            dvr: dvr(validatorjs)
        },
        hooks: {
            onSubmit(form) {
                patchDetail.current = _.cloneDeep(cloneData.current);
                form.$('patchType').value = selectedType;
                const values = form.values();
                Object.keys(values).map((key) => {
                    patchDetail.current[key] = values[key];
                });
            },
            onSuccess(form) {
                if (isEditable && !form.isDirty) {
                    ds.showWarningAlert(t('PatchNote.NoChange'));
                    return;
                }
                setSaveDialog(true);
            },
            onError(form) {
                let errorMsg = "";
                Object.keys(form.errors()).map( (data) => {
                    if( errorMsg === "" ) {
                        errorMsg = form.errors()[data];
                        if( errorMsg === null ) { errorMsg = "";}
                    }
                } );
                ds.showErrorAlert(errorMsg);
                console.log("onError", form.errors());
            },
        }
    });

    let typeText = "";
    if (patchDetail.current.patchType !== undefined) {
        typeText = CommonCodeKey(PatchType, patchDetail.current.patchType);
    }

    let clientText = "";
    if (patchDetail.current.clientName !== undefined) {
        clientText = patchDetail.current.clientName
    }

    const onChangeClient = (event, value) => {
        patchDetail.current.clientKey = value;
        form.$('clientKey').value = value;
    }


    const saveCancelClick = () => {
        patchDetail.current = _.cloneDeep(cloneData.current);
        setSelectedType(cloneData.current.patchType);
        toggleClickHandler(false);
    }

    // EDIT SAVE
    const saveOkClick = () => {
        loadingHandler((prevLoginHandler) => !prevLoginHandler);
        setSaveDialog(false);

        api.setPatchNoteModify(seq, patchDetail.current)
            .then((data) => {
                ds.showInfoAlert(t('PatchNote.SaveSuccess'));
                patchDetail.current = data.data;
                cloneData.current = _.cloneDeep(patchDetail.current);
                loadingHandler(false);
            })
            .catch((err) => {
                ds.showErrorAlert(api.getErrMsg(err.rtCode));
                loadingHandler(false);
            });

        toggleClickHandler(false);
    }

    // DELETE PATCH
    const deleteOkClick = () => {
        api.deletePatchNote(seq)
            .then(() => {
                ds.showInfoAlert(t('PatchNote.DeleteSuccess'));
                history.go(-1);
            })
            .catch((err) => {
                ds.showErrorAlert(api.getErrMsg(err.rtCode));
                loadingHandler(false);
            });

        setDeleteDialog(false);
    }

    const typeArray = [];
    if (typeArray.length === 0) {
        Object.keys(PatchType).map((key, index) => {
            typeArray[index] = {
                key: key,
                value: PatchType[key],
                text: t(CommonCodeValue.translateKey + '.' + PatchType[key])
            };
        });
    }

    /** ----------- EDIT PATCH NOTE FORM ------------ **/
    return (
        <div className={classes.root}>

            <div className={classes.root2}>
                <div className={classes.titleContainer}>
                    <InputTexts
                        form={form}
                        name={'title'}
                        label={t("PatchNote.Title")}
                        value={patchDetail.current.title}
                        isEditable={isEditable}
                        variant={'outlined'}
                    />
                </div>

                <form noValidate autoComplete="off" onSubmit={form?.onSubmit} id={formId}>
                    <div className={classes.headerContainer}>
                        <div>
                            <dl>
                                <dt>{t('PatchNote.RegUser')}</dt>
                                <dd>
                                    <LabelText translation={false} label={patchDetail.current.regUserName}/>
                                </dd>
                            </dl>
                            <dl>
                                <dt>{t('PatchNote.Version')}</dt>
                                <dd style={{minWidth:207}}>
                                    <InputTexts
                                        form={form}
                                        name={'version'}
                                        label={patchDetail.current.version}
                                        value={patchDetail.current.version}
                                        isEditable={isEditable}
                                        style={classes.inputBox}
                                    />
                                </dd>
                            </dl>
                            <dl>
                                <dt>{t('PatchNote.Date')}</dt>
                                <dd>
                                    <LabelText translation={false} value={patchDetail.current.regDt}
                                               dataType={'date'} format={'YYYY-MM-DD HH:mm:ss'}/>
                                </dd>
                            </dl>
                        </div>
                        <div>
                            <dl>
                                <dt>{t('PatchNote.Type')}</dt>
                                <dd>
                                    {!isEditable ?
                                        <LabelText translation={false} label={t('CommonCode.' + typeText)}/>
                                        :
                                        <SelectBox className={classes.selectBox}
                                                   title={t("PatchNote.Type")}
                                                   contents={typeArray}
                                                   value={selectedType}
                                                   onChange={(e) => setSelectedType(e.target.value)}
                                                   addAll={false}
                                        />
                                    }
                                </dd>
                            </dl>
                            <dl>
                                <dt>{t('PatchNote.DeployDate')}</dt>
                                <dd style={{minWidth:207, maxWidth:207}}>
                                    {!isEditable ?
                                        <LabelText translation={false} value={patchDetail.current.deployDt}
                                                   dataType={'date'} format={'YYYY-MM-DD HH:mm:ss'}/>
                                        :
                                        <DateSelectBox form={form} patch={patchDetail.current}/>
                                    }
                                </dd>
                            </dl>
                            <dl>
                                <dt>{t('PatchNote.UptDate')}</dt>
                                <dd>
                                    <LabelText translation={false} value={patchDetail.current.uptDt}
                                               dataType={'date'} format={'YYYY-MM-DD HH:mm:ss'}/>
                                </dd>
                            </dl>
                        </div>
                        <div>
                            <dl>
                                <dt>{t('PatchNote.Client')}</dt>
                                <dd>
                                    {!isEditable ?
                                        <LabelText translation={false} label={clientText} />
                                        :
                                        <AccessClientSelectBox
                                            className={`${classes.selectBox} ${"client-select"}`}
                                            title={t('PatchNote.Client')}
                                            defaultClientKey={patchDetail.current.clientKey} // 이 부분을 추가합니다.
                                            defaultClientName={patchDetail.current.clientName} // 추가
                                            addAll={true}
                                            onChangeSelect={onChangeClient}
                                            field={form.$('clientKey')}
                                        />


                                    }
                                </dd>
                            </dl>
                        </div>
                    </div>

                    <Divider className={classes.Divider}/>

                    <div className={classes.bodyContainer}>
                        { isEditable
                            ?
                            <TextEditor placeholder={t('PatchNote.Content')} field={form.$("content")}/>
                            :
                            <Typography color='textPrimary' dangerouslySetInnerHTML={{__html: patchDetail.current.content}}/>
                        }
                    </div>
                </form>

                <div className={classes.buttonBox}>
                    {!isEditable && ds.user.isSuperManager() ? (
                            <React.Fragment>
                                <Button color={'primary'} variant="contained"
                                        type={'button'} form={formId}
                                        onClick={() => toggleClickHandler(true)}>
                                    {t('PatchNote.Detail.Edit')}
                                </Button>
                                <Button variant="contained" type={'button'}
                                        onClick={() => setDeleteDialog(true)}>
                                    {t('PatchNote.Detail.Delete')}
                                </Button>
                            </React.Fragment>
                        ) :
                        (
                            <React.Fragment>
                                <ProgressButton form={formId} loading={loading}
                                                label={t('PatchNote.Detail.Save')}
                                                color={'primary'}
                                                variant={'contained'}
                                />
                                <Button variant="contained"
                                        type={'button'} form={formId}
                                        className={classes.grayButton}
                                        onClick={saveCancelClick}>
                                    {t('PatchNote.Detail.Cancel')}
                                </Button>
                            </React.Fragment>
                        )
                    }
                </div>
            </div>

            <LoginBackGround loading={loading}/>
            <ConfirmDialog  open={saveDialog}
                            onClose={() => {
                                patchDetail.current = _.cloneDeep(cloneData.current);
                                setSelectedType(cloneData.current.patchType);
                                setSaveDialog(false)
                            }}
                            onOk={saveOkClick}
                            title={t('PatchNote.Detail.ConfirmTitle')}
                            content={t('PatchNote.Detail.EditContent')}/>
            <ConfirmDialog  open={deleteDialog}
                            onClose={() => setDeleteDialog(false)}
                            onOk={deleteOkClick}
                            title={t('PatchNote.Detail.ConfirmTitle')}
                            content={t('PatchNote.Detail.DeleteContent')}/>
        </div>
    )
    /** ----------- EDIT PATCH NOTE END ------------ **/
}

function CommonCodeKey(dataList, data) {
    let dataKey = "";
    Object.keys(dataList).map((key) => {
        if (dataList[key] === data) {
            dataKey = dataList[key];
        }
    });
    return dataKey;
}

function DateSelectBox({form, patch}) {

    const classes = useStyles();
    const [calendarOpen, setCalendarOpen] = useState(false);
    const [date, setDate] = useState(moment(patch.deployDt).utc().format("yyyy-MM-DD"));
    const [dateBtnText, setDateBtnText] = useState(moment(patch.deployDt).utc().format("yyyy-MM-DD HH:mm"));

    const onSelectDate = (value) => {
        setDate(value);
        form.$('deployDt').value = moment(value).format("yyyy-MM-DD HH:mm:ss");
        setDateBtnText(moment(value).format("yyyy-MM-DD HH:mm"))
    }

    return (
        <React.Fragment>
            <Button endIcon={<DateRangeIcon/>}
                    onClick={() => setCalendarOpen(true)}
                    disableRipple
                    className={classes.dateSelectBtn}
            >
                {dateBtnText}
            </Button>
            <DateTimeDialog open={calendarOpen}
                            dateValue={date}
                            onClose={() => setCalendarOpen(false)}
                            timeValue={{
                                hour: moment(patch.deployDt).utc().hours(),
                                min: moment(patch.deployDt).utc().minutes() }}
                            onSubmit={onSelectDate} />
        </React.Fragment>
    )
}