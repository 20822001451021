import React, { useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {useTranslation} from "react-i18next";
import {useMediaQuery} from "@material-ui/core";
import theme from "../../common/theme";

function Modal({ className, onClose, maskClosable, closable, visible, src, top, left, isMobile }) {

    const {t} = useTranslation();

    const onMaskClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose(e)
        }
    }

    // 이전방문 날짜
    const VISITED_BEFORE_DATE = localStorage.getItem('VisitCookie')
    // 현재 날짜
    const VISITED_NOW_DATE = Math.floor(new Date().getDate())

    useEffect(() => {
        // 팝업 오늘 하루닫기 체크
        if (VISITED_BEFORE_DATE !== null) {
            // 날짜가 같을경우 노출
            if (VISITED_BEFORE_DATE === VISITED_NOW_DATE) {
                localStorage.removeItem('VisitCookie')
                onClose(true)
            }
            // 날짜가 다를경우 비노출
            if (VISITED_BEFORE_DATE !== VISITED_NOW_DATE) {
                onClose(false)
            }
        }
    }, [VISITED_BEFORE_DATE])

    // 하루동안 팝업 닫기
    const Dayclose = (e) => {
        if (onClose) {
            onClose(e)

            const expiry = new Date()
            // +1일 계산
            const expiryDate = expiry.getDate() + 1
            // 로컬스토리지 저장
            localStorage.setItem('VisitCookie', expiryDate)
        }
    }

    const close = (e) => {
        if (onClose) {
            onClose(e)
        }
    }

    return (
        <>
            <ModalOverlay visible={visible} />
            <ModalWrapper
                className={className}
                onClick={maskClosable ? onMaskClick : null}
                tabIndex="-1"
                visible={visible}
                top={top}
                left={left}
            >
                <ModalInner tabIndex="0" className="modal-inner" isMobile={isMobile}>
                    <ModalInner2>
                        <ImgStyle>
                            <img //src={t(`MainPagePopUp.src`)}
                                src="https://resources.fnsvalue.co.kr/notice/2024_09.jpg"
                                 style={{ width: '100%', height: '100%' }} alt="" />
                        </ImgStyle>
                        {closable && (
                            <CloseStyle isMobile={isMobile}>
                                <Close className="modal-close" onClick={Dayclose}>
                                    오늘 하루 닫기
                                </Close>
                                <Close className="modal-close" onClick={close}>
                                    닫기
                                </Close>
                            </CloseStyle>
                        )}
                    </ModalInner2>
                </ModalInner>
            </ModalWrapper>
        </>
    )
}

Modal.propTypes = {
    visible: PropTypes.bool,
}

const ModalInner2 = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const ImgStyle = styled.div``

const CloseStyle = styled.div`
    display: flex;
    justify-content: space-between;
    background-color: #282828;
    width: ${(props) => (props.isMobile ? '220px' : '100%')};
    padding: 15px;
    border-radius: 0 0 15px 15px;
    color: #ffffff;
    margin-top : -10px;
`

const Close = styled.span`
    cursor: pointer;
`

const ModalWrapper = styled.div`
    box-sizing: border-box;
    display: ${(props) => (props.visible ? 'flex' : 'none')};
    position: fixed;
    top: ${(props) => (props.top  ? props.top : 0)};
    right: 0;
    bottom: 0;
    left: ${(props) => (props.left ? props.left : 0)};
    z-index: 1000;
    overflow: auto;
    outline: 0;
`

const ModalOverlay = styled.div`
    box-sizing: border-box;
    display: ${(props) => (props.visible ? 'block' : 'none')};
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 999;
`

const ModalInner = styled.div`
    box-sizing: border-box;
    position: relative;
    // box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
    // background-color: #fff;
    // border-radius: 10px;
    width: ${(props) => (props.isMobile ? '260px' : '100%')};   
    max-width: 700px;
    max-height: 100%;
    top: 50%;
    left : calc(50% - 350px);
    transform: translateY(-50%);
    //margin: 0 auto;
    padding: 20px 20px;
`

export default React.memo(Modal)