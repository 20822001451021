import {useTranslation} from "react-i18next";
import {useStores} from "../../common/store";
import React, {useRef, useState} from "react";
import _ from "lodash";
import MobxReactForm from "mobx-react-form";
import dvr from "mobx-react-form/lib/validators/DVR";
import validatorjs from "validatorjs";
import {PatchType} from "../../common/types";
import api from "../../common/api";
import InputSelect from "../../components/InputSelect";
import InputTexts from "../../components/InputTexts";
import TextEditor from "../../components/TextEditor";
import Button from "@material-ui/core/Button";
import ConfirmDialog from "../../components/ConfirmDialog";
import {makeStyles} from "@material-ui/core/styles";
import moment from "moment";
import Box from "@material-ui/core/Box";
import DateRangeIcon from "@mui/icons-material/DateRange";
import DateTimeDialog from "../../components/DateTimeDialog";
import FormHelperText from "@material-ui/core/FormHelperText";
import {useObserver} from "mobx-react-lite";
import FormControl from "@material-ui/core/FormControl";
import AccessClientSelectBox from "../../components/AccessClientSelectBox";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '1.875rem',
        maxWidth: 660
    },
    formControl: {
        margin: 0,
        width: '100%'
    },
    selectContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        '& .MuiInputBase-root': {
            fontSize: '0.813rem',
            minWidth: 290,
            maxWidth: '100%',
            width: '100%',
            [theme.breakpoints.between('xs', 'md')]: {
                minWidth: 'auto'
            }
        },
        '& > *': {
            ...theme.select,
            minWidth: 290,
            maxWidth: '100%',
            flex: 1,
            marginBottom: theme.spacing(1.25),
            '& .MuiFormControl-root': {
                width: '100%',
                minWidth: 290,
            },
            '& .MuiFormHelperText-contained': {
                margin: 0,
                fontSize: '0.438rem'
            },
            [theme.breakpoints.between('xs', 'md')]: {
                maxWidth: '100%'
            },
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                minWidth: 160,
            }
        },
        '& > div:first-child, div:nth-child(3)': { marginRight: theme.spacing(1) },
        '& > div:nth-child(3), div:nth-child(4)': {
            minWidth: '100%'
        },
        '& > div:nth-child(4)': { marginRight: 0},
        '& > div:nth-child(5)': { maxWidth: '100%' },

        [theme.breakpoints.between('xs', 'sm')]: {
            flex: 1,
            flexDirection: 'column',
            marginBottom: 0,
            '& > div:first-child': {marginRight: 0},
            '& > div:nth-child(2)': {marginLeft: 0},
        }
    },
    dateSelectBtn: {
        height: 40,
        minWidth: 250,
        maxWidth: '100%',
        width: 'auto',
        border: '1px solid #DBDADA',
        borderRadius: 4,
        justifyContent: 'flex-start',
        padding: '8px 15px',
        transitionDuration: 0,
        '& .MuiButton-label': {
            color: '#565568',
            fontSize: 12,
            width: '100%',
            lineHeight: '20px'
        },
        '& .MuiButton-endIcon': {
            marginLeft: 'auto',
            color: '#8D96AB',
            '& > svg': {
                width: 20,
                height: 20
            }
        },
        '&:hover': {
            backgroundColor: 'transparent',
            border: '1px solid #3C39CB',
            '& .MuiButton-label': {
                color: '#222',
            },
            '& .MuiButton-endIcon': {
                color: '#3C39CB'
            },
        }
    },
    bodyContainer:{
        display:'flex',
        flexDirection:'column',
        '& > div':{
            marginBottom:theme.spacing(2),
        },
        '& > div:first-child':{
            minWidth: 650,
            [theme.breakpoints.between('xs', 'md')]: {
                minWidth:'initial',
                flex:1,
            }
        },
    },
    inputBox: {
        minWidth: 200,
        '& .MuiInputBase-root': {
            height: 40,
            paddingRight: 15,
            '& fieldset': {
                height: 40,
                top: 0,
                '& legend, legend span': {
                    display: 'none'
                }
            },
            '& input::placeholder': {
                color: '#565568',
                opacity: 1
            }
        },
        '& .MuiOutlinedInput-input': {
            padding: '5px 15px',
            height: 30,
            fontSize: '0.75rem'
        },
        '& .MuiInputAdornment-root': {
            '& > button': {
                padding: 0,
                color: '#8D96AB',
                '& svg': {
                    fontSize: '20px'
                }
            }
        },
        '&:hover .MuiInputAdornment-root > button': {
            color: theme.palette.primary.main
        }
    },
    errorText: {
        fontSize: '0.438rem',
        color: '#f44336'
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        '& > *': {
            width: 130,
            height: 40,
            borderRadius: 5,
            fontSize: '0.75rem',
            fontWeight: 700
        },
        '& > :first-child': {
            border: '1px solid #C7CBD1',
            backgroundColor: 'transparent',
            color: '#81808A',
            marginRight: 6,
            "&:hover": {
                border: '1px solid #3C39CB',
                backgroundColor: 'transparent',
                color: theme.palette.primary.main
            }
        },
        '& > :last-child': {
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
            "&:hover": {
                backgroundColor: theme.palette.secondary.main,
                color: '#fff',
            }
        }
    }
}));


export default function PatchNoteCreate({onClose}) {

    const classes = useStyles();
    const {t} = useTranslation();
    const {ds} = useStores();
    const [openConfirm, setOpenConfirm] = useState(false);

    const patchDetail = useRef(
        {
            regUserKey:ds.user.userKey,
            regUserName:ds.user.name,
            patchType:"",
            title:"",
            version:"",
            content:"",
            deployDt:"",
            clientKey:""
        }
    );

    const cloneData = useRef(
        {
            regUserKey:ds.user.userKey,
            regUserName:ds.user.name,
            patchType:"",
            title:"",
            version:"",
            content:"",
            deployDt:"",
            clientKey:""
        }
    );

    const onChangeClient = (event, value) => {
        patchDetail.current.clientKey = value;
        form.$('clientKey').value = value;
    }

    const saveCancelClick = () => {
        patchDetail.current = _.cloneDeep(cloneData.current);
        onClose();
    }

    const formId = "patchNoteForm";
    const form = new MobxReactForm({
        fields: [
            {name: 'patchType', value: patchDetail.current.patchType, rules: 'required', label: t('PatchNote.Type')},
            {name: 'version', value: patchDetail.current.version, rules: 'required', label: t('PatchNote.Type')},
            {
                name: 'title',
                value: patchDetail.current.title,
                rules: 'required',
                label: t("PatchNote.Title"),
            },
            {
                name: 'clientKey',
                value: patchDetail.current.clientKey,
                label: t('PatchNote.Client'),
                rules: 'required'
            },
            {
                name: 'content',
                value: patchDetail.current.content,
                rules: 'required',
                label: t("PatchNote.Content"),
            },
            {
                name: 'deployDt',
                value: patchDetail.current.deployDt,
                rules: 'required',
                label: t("PatchNote.DeploymentDate"),
            },
        ]
    }, {
        plugins: {
            dvr: dvr(validatorjs)
        },
        hooks: {
            onSuccess(form) {
                const values = form.values();
                Object.keys(values).map( (key) => {
                    if (values[key] !== null && values[key] !== "") {
                        patchDetail.current[key] = values[key];
                    }
                });
                setOpenConfirm(true);
            },
            onError(form) {
                let errorMsg = "";
                Object.keys(form.errors()).map( (data) => {
                    if( errorMsg === "" ) {
                        errorMsg = form.errors()[data];
                        if( errorMsg === null ) { errorMsg = "";}
                    }
                } );
                ds.showErrorAlert(errorMsg);
                console.log("onError", form.errors());
            }
        }
    });

    let typeText = "";
    if (patchDetail.current.patchType !== undefined) {
        typeText = CommonCodeKey(PatchType, patchDetail.current.patchType);
    }

    const onClickBtn = (isYes) => {
        if( isYes ) {
            api.postPatchNote(patchDetail.current)
                .then(() => {
                    onClose(true);
                    window.location.reload(false);
                    ds.showInfoAlert(t('PatchNote.RegistrationSuccess'));
                })
                .catch(err => {
                    ds.showErrorAlert(api.getErrMsg(err.rtCode));
                });
        }
        setOpenConfirm(false);
    }


    return (
        <Box className={classes.root}>
            <form noValidate autoComplete="off" onSubmit={form?.onSubmit} id={formId}>
                <div className={classes.selectContainer}>
                    <InputSelect
                        form={form}
                        name={'patchType'}
                        label={t('PatchNote.Type')}
                        placeholder={t('PatchNote.Type')}
                        selectType={PatchType}
                        value={patchDetail.current.patchType}
                        text={typeText}
                        variant={'outlined'}
                    />

                    <Box className={classes.inputBox}>
                        <DateSelectBox form={form} />
                    </Box>

                    <AccessClientSelectBox className={`${classes.selectBox} ${"client-select"}`}
                                           title={t('PatchNote.Client')}
                                           defaultClientKey={""}
                                           addAll={true}
                                           onChangeSelect={onChangeClient}
                                           required={true}
                                           field={form.$('clientKey')}/>

                    <InputTexts
                        style={classes.inputBox}
                        form={form}
                        name={'version'}
                        placeholder={t('PatchNote.Version')}
                        value={patchDetail.current.version}
                        variant={'outlined'}
                    />
                    <InputTexts
                        style={classes.inputBox}
                        form={form}
                        name={'title'}
                        placeholder={t('PatchNote.Title')}
                        variant={'outlined'}
                    />

                </div>
                <div style={{marginBottom: '50px'}}>
                    <TextEditor placeholder={t('PatchNote.Content')} field={form.$("content")} height={250}/>
                </div>

                <div className={classes.buttonContainer}>
                    <Button type={'button'} onClick={saveCancelClick} className={classes.cancelButton}>
                        {t('PatchNote.Detail.Cancel')}
                    </Button>
                    <Button onClick={form.onSubmit} className={classes.saveButton}>
                        {t('PatchNote.Detail.Save')}
                    </Button>
                </div>
                <ConfirmDialog
                    open={openConfirm}
                    onClose={() => onClickBtn(false)}
                    onOk={() => onClickBtn(true)}
                    title={'PatchNote.CreateDialog.ConfirmTitle'}
                    content={'PatchNote.CreateDialog.ConfirmContent'}/>
            </form>
        </Box>
    )
}

function DateSelectBox({form}) {

    const classes = useStyles();
    const {t} = useTranslation();
    const [calendarOpen, setCalendarOpen] = useState(false);
    const [date, setDate] = useState(new Date());
    const [dateBtnText, setDateBtnText] = useState(t("PatchNote.DeploymentDate"));

    const onSelectDate = (value) => {
        setDate(value);
        setDateBtnText(value)
        form.$('deployDt').value = moment(value).format("yyyy-MM-DD HH:mm:ss");
    }

    return useObserver(() => (
        <FormControl variant="outlined" className={classes.formControl}>
            <Button endIcon={<DateRangeIcon/>}
                    onClick={() => setCalendarOpen(true)}
                    disableRipple
                    className={classes.dateSelectBtn}
            >
                {dateBtnText}
            </Button>
            {form.$('deployDt').error && <FormHelperText className={classes.errorText}>{form.$('deployDt').error}</FormHelperText>}
            <DateTimeDialog open={calendarOpen}
                            dateValue={date}
                            onClose={() => setCalendarOpen(false)}
                            onSubmit={onSelectDate} />
        </FormControl>
    ));
}


function CommonCodeKey(dataList, data) {
    let dataKey = "";
    Object.keys(dataList).map((key) => {
        if (dataList[key] === data) {
            dataKey = dataList[key];
        }
    });
    return dataKey;
}