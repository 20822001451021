import {useTranslation} from "react-i18next";
import {useObserver} from "mobx-react-lite";
import {Dialog, DialogContent, DialogTitle} from "@mui/material";
import Typography from "@material-ui/core/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {useMediaQuery} from "@material-ui/core";
import "../css/privacy-tags.css"
import ImageGrid from "./ImageGrid"

//커스텀 style 지정
const detailContentStyle = makeStyles((theme) => ({
    dialog: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-around',
        '& .css-1jh78f5-MuiPaper-root-MuiDialog-paper.MuiDialog-paperScrollBody': {
            backgroundColor: theme.palette.secondary.main,
            border: '1px solid #171E31',
            borderRadius: 5,
            boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.35)'
        },
    },
    dialogHeader: {
        backgroundColor: theme.palette.secondary.main,
        height: 60,
        '& .css-i4bv87-MuiSvgIcon-root': {
            width: 27,
            height: 25
        }
    },
    dialog_content: {
        padding: "15px 30px 30px 30px !important",
        backgroundColor: '#fff',
        wordBreak: "keep-all"
    },
    title: {
        color: theme.palette.text.primary,
        ...theme.typography.h5,
        "& > *": {
            color: theme.palette.text.white,
            fontSize: "1.125rem"
        }
    },
    headerContainer: {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: 10,
        "& > *": {
            color: theme.palette.text.primary,
        },
        '& > div:first-child': {
            minWidth: 130
        },
        [theme.breakpoints.down('sm')]: {
            overflowX: 'scroll'
        }

    },
    headerInfo: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        flexFlow: 'wrap',
        borderBottom: '1px solid #DBDADA',
        '& > div': {
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            minHeight: 50,
            borderBottom: '1px solid #DBDADA',
            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column',
                borderBottom: 'none',
            },
            '& > dl': {
                display: 'flex',
                alignItems: 'center',
                minWidth: 295,
                margin: "8px 0",
                '& > *': {
                    fontFamily: ['Gmarket Sans', 'Noto Sans KR', 'sans-serif']
                },
                '& > dt': {
                    color: theme.palette.primary.main,
                    fontSize: '0.688rem',
                    fontWeight: 700
                },
                '& > dd': {
                    fontSize: '14px',
                    color: '#555',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    marginRight: theme.spacing(1),
                    marginInlineStart: theme.spacing(1),
                },
                [theme.breakpoints.down('xs')]: {
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    borderBottom: '1px solid #DBDADA',
                    padding: '8px 0',
                    margin: 0,
                    minWidth: 100,
                    '& span': {
                        fontSize: '0.75rem'
                    },
                    '& > dd': {
                        marginInlineStart: 0
                    }
                }
            }
        },
        '& > :last-child': {
            borderBottom: 'none',
        }
    },
    privacyPolicyTitle: {
        fontSize: 24,
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: 15
    },
    privacyPolicySubTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 5
    },
    privacyPolicySubTitleBox: {
        margin: '10px 0px 20px 0px',
        '& > div > p': {
            margin: '5px 0px 5px 5px'
        },
        '& > div > ul > li > p': {
            margin: '10px 0px 0px 0px'
        },
        '& > div > ul > li:first-child > p': {
            margin: '0px'
        },
        '& > div > ul > li > p:last-child' : {
            marginLeft: '10px',
            marginTop: '0px'
        },
    },
    privacyIcon: {
        width: 30,
        verticalAlign: 'middle',
        marginRight: 5
    },
    privacyContentIcon: {
        width: 30,
        verticalAlign: 'middle',
        marginLeft: 5
    },
    privacySummary: {
        border: '1px solid #ACACAC',
        borderRadius: '5px',
        padding: '15px 20px',
        marginBottom: '20px',
        '& > div': {
            margin: '5px 0px',
            fontWeight: 'bold'
        }
    },

}));

/*
    상세 dialog 구성
    open : dialog 호출 여부
    oncloseEvent : 닫기 이벤트
    content : 테이블 row 값
 */
export default function PrivacyPolicyDialog({open, onCloseEvent}) {

    //호출 여부가 false면 열리지 않는다.
    if (!open) {
        return null;
    }

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));  //mobile 여부
    const {t} = useTranslation();
    const classes = detailContentStyle();                                   //커스텀 Style

    let ht = isMobile ? "100%" : "763";

    return useObserver(() =>
        <Dialog className={classes.dialog} open={open} onClose={onCloseEvent} maxWidth={'lg'}>
            <DialogTitle className={classes.dialogHeader}>
                <Typography className={classes.title}>
                    <span>개인정보처리방침</span>
                </Typography>
                {onCloseEvent ? (
                    <IconButton
                        aria-label="close"
                        onClick={onCloseEvent}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: '#fff'
                        }}
                    >
                        <CloseIcon/>
                    </IconButton>
                ) : null}
            </DialogTitle>
            <DialogContent className={classes.dialog_content}>
                <div className={classes.headerContainer}>
                    <div className={classes.headerInfo}>
                        <div>
                            <dl>
                                <dt>시행일자</dt>
                                <dd><span>2024.07.01</span></dd>
                            </dl>
                        </div>
                        <div>
                            <dl>
                                <dd>
                                    <div className={classes.privacyPolicyTitle}>개인정보처리방침</div>
                                    <div style={{fontSize: '16px', marginBottom: '30px'}}>
                                        <b>(주)에프엔에스벨류</b>(이하 '회사')는 <b>「개인정보 보호법」</b> 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.
                                    </div>

                                    <ImageGrid/>

                                    <div style={{fontSize: '16px', margin: '10px 0px'}}>「개인정보처리방침」은 다음과 같은 내용을 담고 있습니다.</div>

                                    <div className={classes.privacySummary}>
                                        <div><a href={'#privacy1'}><img alt="" className={classes.privacyIcon} src={`${require('../images/fnsv/privacyPolicy/privacy_1.png')}`} />제1조 개인정보의 처리 목적</a></div>
                                        <div><a href={'#privacy2'}><img alt="" className={classes.privacyIcon} src={`${require('../images/fnsv/privacyPolicy/privacy_2.png')}`} />제2조 개인정보의 처리 및 보유 기간</a></div>
                                        <div><a href={'#privacy3'}><img alt="" className={classes.privacyIcon} src={`${require('../images/fnsv/privacyPolicy/privacy_3_1.png')}`} />제3조 처리하는 개인정보의 항목</a></div>
                                        <div><a href={'#privacy4'}><img alt="" className={classes.privacyIcon} src={`${require('../images/fnsv/privacyPolicy/privacy_4.png')}`} />제4조 개인정보의 제3자 제공에 관한 사항</a></div>
                                        <div><a href={'#privacy5'}><img alt="" className={classes.privacyIcon} src={`${require('../images/fnsv/privacyPolicy/privacy_5.png')}`} />제5조 개인정보처리의 위탁에 관한 사항</a></div>
                                        <div><a href={'#privacy6'}><img alt="" className={classes.privacyIcon} src={`${require('../images/fnsv/privacyPolicy/privacy_6.png')}`} />제6조 개인정보의 파기절차 및 파기방법</a></div>
                                        <div><a href={'#privacy7'}><img alt="" className={classes.privacyIcon} src={`${require('../images/fnsv/privacyPolicy/privacy_7.png')}`} />제7조 정보주체와 법정대리인의 권리‧의무 및 그 행사방법에 관한 사항</a></div>
                                        <div><a href={'#privacy8'}><img alt="" className={classes.privacyIcon} src={`${require('../images/fnsv/privacyPolicy/privacy_8.png')}`} />제8조 개인정보의 안전성 확보조치에 관한 사항</a></div>
                                        <div><a href={'#privacy9'}><img alt="" className={classes.privacyIcon} src={`${require('../images/fnsv/privacyPolicy/privacy_9.png')}`} />제9조 개인정보를 자동으로 수집하는 장치의 설치‧운영 및 그 거부에 관한 사항</a></div>
                                        <div><a href={'#privacy10'}><img alt="" className={classes.privacyIcon} src={`${require('../images/fnsv/privacyPolicy/privacy_10.png')}`} />제10조 개인정보 보호책임자에 관한 사항</a></div>
                                        <div><a href={'#privacy11'}><img alt="" className={classes.privacyIcon} src={`${require('../images/fnsv/privacyPolicy/privacy_11.png')}`} />제11조 개인정보의 열람청구를 접수‧처리하는 부서</a></div>
                                        <div><a href={'#privacy12'}><img alt="" className={classes.privacyIcon} src={`${require('../images/fnsv/privacyPolicy/privacy_12.png')}`} />제12조 정보주체의 권익침해에 대한 구제방법</a></div>
                                        <div><a href={'#privacy13'}><img alt="" className={classes.privacyIcon} src={`${require('../images/fnsv/privacyPolicy/privacy_13.png')}`} />제13조 개인정보 처리방침 변경</a></div>
                                    </div>

                                    <div id={'privacy1'} className={classes.privacyPolicySubTitleBox}>
                                        <div className={classes.privacyPolicySubTitle}>제1조(개인정보의 처리 목적)<img className={classes.privacyContentIcon} src={`${require('../images/fnsv/privacyPolicy/privacy_1.png')}`} /></div>
                                        <div>
                                            <p><b>회사</b>는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.</p>
                                            <ul>
                                                <li>
                                                    1. BSA 서비스
                                                    <ul>
                                                        <li>
                                                            회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 서비스 부정이용 방지 목적, 서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div id={'privacy2'} className={classes.privacyPolicySubTitleBox}>
                                        <div className={classes.privacyPolicySubTitle}>제2조(개인정보의 처리 및 보유 기간)<img className={classes.privacyContentIcon} src={`${require('../images/fnsv/privacyPolicy/privacy_2.png')}`} /></div>
                                        <div>
                                            <p>① <b>회사</b>는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의 받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.</p>
                                            <p>② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.</p>
                                            <ul>
                                                <li>
                                                    1. BSA 서비스
                                                    <ul>
                                                        <li>
                                                            BSA 서비스와 관련한 개인정보는 수집.이용에 관한 동의일로부터 <b>회원 탈퇴 시</b>까지 위 이용목적을 위하여 보유·이용됩니다.
                                                            <ul>
                                                                <li>
                                                                    - 보유근거 : 「개인정보보호법」 제15조(개인정보의 수집·이용) 제1항
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div id={'privacy3'} className={classes.privacyPolicySubTitleBox}>
                                        <div className={classes.privacyPolicySubTitle}>제3조(처리하는 개인정보의 항목)<img className={classes.privacyContentIcon} src={`${require('../images/fnsv/privacyPolicy/privacy_3_1.png')}`} />
                                            <img className={classes.privacyContentIcon} src={`${require('../images/fnsv/privacyPolicy/privacy_3_2.png')}`} /></div>
                                        <div>
                                            <p>① <b>회사</b>는 다음의 개인정보 항목을 처리하고 있습니다.</p>
                                            <ul>
                                                <li>
                                                    1. BSA 서비스
                                                    <ul>
                                                        <li>
                                                            <b>- 필수항목 : 이름, 로그인ID, 휴대전화번호, 이메일, 기기정보(디바이스 아이디)</b>
                                                        </li>
                                                        <li>
                                                            - 선택항목 : 해당사항 없음
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div id={'privacy4'} className={classes.privacyPolicySubTitleBox}>
                                        <div className={classes.privacyPolicySubTitle}>제4조(개인정보의 제3자 제공에 관한 사항)<img className={classes.privacyContentIcon} src={`${require('../images/fnsv/privacyPolicy/privacy_4.png')}`} /></div>
                                        <div>
                                            <p>① <b>회사</b>는 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 「개인정보 보호법」 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.</p>
                                            <p>② <b>회사</b>는 다음과 같이 재난, 감염병, 급박한 생명·신체 위험을 초래하는 사건·사고, 급박한 재산 손실 등의 긴급상황이 발생하는 경우 정보주체의 동의 없이 관계기관에 개인정보를 제공할 수 있습니다</p>
                                        </div>
                                    </div>

                                    <div id={'privacy5'} className={classes.privacyPolicySubTitleBox}>
                                        <div className={classes.privacyPolicySubTitle}>제5조(개인정보처리의 위탁에 관한 사항)<img className={classes.privacyContentIcon} src={`${require('../images/fnsv/privacyPolicy/privacy_5.png')}`} /></div>
                                        <div>
                                            <p>① <b>회사</b>는 현재 개인정보 처리업무를 위탁하고 있지 않습니다.</p>
                                            <p>② 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.</p>
                                        </div>
                                    </div>

                                    <div id={'privacy6'} className={classes.privacyPolicySubTitleBox}>
                                        <div className={classes.privacyPolicySubTitle}>제6조(개인정보의 파기절차 및 파기방법)<img className={classes.privacyContentIcon} src={`${require('../images/fnsv/privacyPolicy/privacy_6.png')}`} /></div>
                                        <div>
                                            <p>① <b>회사</b>는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.</p>
                                            <p>② 정보주체로부터 동의 받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.</p>
                                            <p>③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.</p>
                                            <ul>
                                                <li>
                                                    1. 파기절차
                                                    <ul>
                                                        <li>
                                                            <b>회사</b>는 파기 사유가 발생한 개인정보를 선정하고, <b>회사</b>의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    2. 파기방법
                                                    <ul>
                                                        <li>- 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.</li>
                                                        <li>- 전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다</li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div id={'privacy7'} className={classes.privacyPolicySubTitleBox}>
                                        <div className={classes.privacyPolicySubTitle}>제7조(정보주체와 법정대리인의 권리·의무 및 그 행사방법에 관한 사항)<img className={classes.privacyContentIcon} src={`${require('../images/fnsv/privacyPolicy/privacy_7.png')}`} /></div>
                                        <div>
                                            <p>① 정보주체는 <b>회사</b>에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.</p>
                                            <p>② 제1항에 따른 권리 행사는 <b>회사</b>에 대해 「개인정보 보호법」 시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 <b>회사</b>는 이에 대해 지체 없이 조치하겠습니다.</p>
                                            <p>③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.</p>
                                            <p>④ 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한될 수 있습니다.</p>
                                            <p>⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.</p>
                                            <p>⑥ <b>회사</b>는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.</p>
                                        </div>
                                    </div>

                                    <div id={'privacy8'} className={classes.privacyPolicySubTitleBox}>
                                        <div className={classes.privacyPolicySubTitle}>제8조(개인정보의 안전성 확보조치에 관한 사항)<img className={classes.privacyContentIcon} src={`${require('../images/fnsv/privacyPolicy/privacy_8.png')}`} /></div>
                                        <div>
                                            <p><b>회사</b>는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.</p>
                                            <ul>
                                                <li>
                                                    <p>1. 내부관리계획의 수립 및 시행</p>
                                                    <p>개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.</p>
                                                </li>
                                                <li>
                                                    <p>2. 개인정보 취급 직원의 최소화 및 교육</p>
                                                    <p>개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화하여 개인정보를 관리하는 대책을 시행하고 있습니다.</p>
                                                </li>
                                                <li>
                                                    <p>3. 정기적인 자체 감사 실시</p>
                                                    <p>개인정보 취급 관련 안정성 확보를 위해 정기적(분기 1회)으로 자체 감사를 실시하고 있습니다.</p>
                                                </li>
                                                <li>
                                                    <p>4. 개인정보에 대한 접근 제한</p>
                                                    <p>개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.</p>
                                                </li>
                                                <li>
                                                    <p>5. 접속기록의 보관 및 위변조 방지</p>
                                                    <p>개인정보처리시스템에 접속한 기록을 최소 1년 이상 보관, 관리하고 있으며, 다만, 5만명 이상의 정보주체에 관하여 개인정보를 추가하거나, 고유식별정보 또는 민감정보를 처리하는 경우에는 2년이상 보관, 관리하고 있습니다.
                                                        또한, 접속기록이 위변조 및 도난, 분실되지 않도록 보안기능을 사용하고 있습니다.</p>
                                                </li>
                                                <li>
                                                    <p>6. 개인정보의 암호화</p>
                                                    <p>이용자의 기기정보는 암호화되어 저장 및 관리되고 있으며, 중요한 데이터는 파일 및 전송 데이터를 암호화하는 등의 별도 보안기능을 사용하고 있습니다.</p>
                                                </li>
                                                <li>
                                                    <p>7. 해킹 등에 대비한 기술적 대책</p>
                                                    <p><b>회사</b>는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.</p>
                                                </li>
                                                <li>
                                                    <p>8. 비인가자에 대한 출입 통제</p>
                                                    <p>개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.</p>
                                                </li>
                                                <li>
                                                    <p>9. 문서보안을 위한 잠금장치 사용</p>
                                                    <p>개인정보가 포함된 서류, 보조저장매체 등을 잠금 장치가 있는 안전한 장소에 보관하고 있습니다.</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div id={'privacy9'} className={classes.privacyPolicySubTitleBox}>
                                        <div className={classes.privacyPolicySubTitle}>제9조(개인정보를 자동으로 수집하는 장치의 설치·운영 및 그 거부에 관한 사항)<img className={classes.privacyContentIcon} src={`${require('../images/fnsv/privacyPolicy/privacy_9.png')}`} /></div>
                                        <div>
                                            <p>1. <b>회사</b>는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.</p>
                                            <p>2. 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.</p>
                                            <ul>
                                                <li>
                                                    1) 쿠키의 사용 목적
                                                    이용자의 아이디를 저장하여 최적화된 서비스 제공을 위해 사용됩니다.
                                                </li>
                                                <li>
                                                    2) 쿠키의 설치/운영 및 거부
                                                    웹 브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부할 수 있습니다.
                                                </li>
                                                <li>
                                                    3) 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div id={'privacy10'} className={classes.privacyPolicySubTitleBox}>
                                        <div className={classes.privacyPolicySubTitle}>제10조 (개인정보 보호책임자에 관한 사항)<img className={classes.privacyContentIcon} src={`${require('../images/fnsv/privacyPolicy/privacy_10.png')}`} /></div>
                                        <div>
                                            <p>① <b>회사</b>는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.</p>
                                            <ul>
                                                <li>
                                                    ▶ 개인정보 보호책임자
                                                    <ul>
                                                        <li>•	성명 : 진성광</li>
                                                        <li>•	직책 : 정보보안책임자</li>
                                                        <li>•	직급 : 연구소장</li>
                                                        <li>•	연락처
                                                            <ul>
                                                                <li>tel : 02-303-3885</li>
                                                                <li>email : skjeen@fnsvalue.co.kr</li>
                                                                <li>fax : 02-304-3885</li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    ▶ 개인정보 보호 담당부서
                                                    <ul>
                                                        <li>•	부서명 : 솔루션사업부</li>
                                                        <li>•	담당자 : 최수영</li>
                                                        <li>•	연락처
                                                            <ul>
                                                                <li>tel : 02-303-3885</li>
                                                                <li>email : syc0623@fnsvalue.co.kr</li>
                                                                <li>fax : 02-304-3885</li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                            <p>② 정보주체께서는 <b>회사</b>의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. <b>회사</b>는 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.</p>
                                        </div>
                                    </div>

                                    <div id={'privacy11'} className={classes.privacyPolicySubTitleBox}>
                                        <div className={classes.privacyPolicySubTitle}>제11조(개인정보의 열람청구를 접수·처리하는 부서)<img className={classes.privacyContentIcon} src={`${require('../images/fnsv/privacyPolicy/privacy_11.png')}`} /></div>
                                        <div>
                                            <p>정보주체는 ｢개인정보 보호법｣ 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다.</p>
                                            <p><b>회사</b>는 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.</p>
                                            <ul>
                                                <li>
                                                    ▶ 개인정보 열람청구 접수·처리 부서
                                                    <ul>
                                                        <li>•	부서명 : 경영지원부</li>
                                                        <li>•	담당자 : 김주현</li>
                                                        <li>•	연락처
                                                            <ul>
                                                                <li>tel : 02-303-3885</li>
                                                                <li>email : fnsvalue@fnsvalue.co.kr</li>
                                                                <li>fax : 02-304-3885</li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div id={'privacy12'} className={classes.privacyPolicySubTitleBox}>
                                        <div className={classes.privacyPolicySubTitle}>제12조(정보주체의 권익침해에 대한 구제방법)<img className={classes.privacyContentIcon} src={`${require('../images/fnsv/privacyPolicy/privacy_12.png')}`} /></div>
                                        <div>
                                            <p>정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.</p>
                                            <ul>
                                                <li>1. 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)</li>
                                                <li>2. 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)</li>
                                                <li>3. 대검찰청 : (국번없이) 1301 (www.spo.go.kr)</li>
                                                <li>4. 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)</li>
                                            </ul>
                                        </div>
                                        <p>「개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대 하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.</p>
                                        <p>※ 행정심판에 대해 자세한 사항은 중앙행정심판위원회(www.simpan.go.kr) 홈페이지를 참고하시기 바랍니다.</p>
                                    </div>

                                    <div id={'privacy13'} className={classes.privacyPolicySubTitleBox}>
                                        <div className={classes.privacyPolicySubTitle}>제13조(개인정보 처리방침 변경)<img className={classes.privacyContentIcon} src={`${require('../images/fnsv/privacyPolicy/privacy_13.png')}`} /></div>

                                        <div>① 이 개인정보처리방침은 2024년 7월 1일부터 적용됩니다.</div>
                                    </div>
                                </dd>
                            </dl>
                        </div>
                    </div>
                </div>

            </DialogContent>
        </Dialog>
    )
}