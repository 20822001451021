import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {useHistory} from "react-router";
import api from "../../common/api";
import {AuthPlatform, AuthResult, CommonCodeValue, UserType} from "../../common/types";
import {useStores} from "../../common/store";
import queryString from 'query-string';
import {addFilter, HistoryPage, isEmpty, TableHeadSort} from "./HistoryCommon";

/**** 인증 이력 페이지:
 * 나의 인증 이력 조회, 선택한 사용자의 인증 이력 조회, 모든 사용자의 인증 이력 조회
 * @param pageType : string can be "myHistory"(나의 이력), "all"(모든 사용자의 이력) or "undefined"(선택한 사용자의 이력)
 * ****/
export default function AuthHistoryPage(props) {

    const {t} = useTranslation();
    const history = useHistory();
    const {ds} = useStores();
    const querySearch = props.params === undefined ? queryString.parse(props.location.search) : queryString.parse(props.params.location.search);
    const [dateRange, setDateRange] = useState(null);
    const { pageType } = props;

    const [clientKey, setClientKey] = useState(querySearch.clientKey ? querySearch.clientKey : 'ALL');
    const [status, setStatus] = useState(querySearch.status ? querySearch.status : 'ALL');
    const [platform, setPlatform] = useState(querySearch.authPlatform ? querySearch.authPlatform : 'ALL');

    const [userKey, setUserKey] = useState(querySearch.userKey ? querySearch.userKey : '');
    const [clientName, setClientName] = useState(querySearch.clientName ? querySearch.clientName : '');

    const [filters, setFilters] = useState([])
    const [page, setPage] = useState(querySearch.page ? parseInt(querySearch.page) : 0);
    const [pageSize, setPageSize] = useState(querySearch.size ? parseInt(querySearch.size)  : 5);
    const [sort, setSort] = useState(querySearch.sort ? querySearch.sort  : 'REG_DT,DESC');

    let today = moment(new Date()).format('YYYY-MM-DD');
    const todayStart = today + " 00:00";
    const todayEnd = today + " 23:59";
    const [minDt, setMinDt] = useState(null);
    const [maxDt, setMaxDt] = useState(null);
    const [startDt, setStartDt] = useState(querySearch.startDt ? querySearch.startDt : todayStart);
    const [endDt, setEndDt] = useState(querySearch.endDt ? querySearch.endDt : todayEnd);
    const [startErr, setStartErr] = useState(false);
    const [endErr, setEndErr] = useState(false);
    const [resetUpt, setResetUpt] = useState(false);

    const [response, setResponse] = useState(null);

    const query = useRef({
        clientKey:      isEmpty(clientKey),
        clientName:     isEmpty(clientName),
        status:         isEmpty(status),
        platform:       isEmpty(platform),
        userKey:        isEmpty(userKey),
        startDt:        isEmpty(startDt),
        endDt:          isEmpty(endDt)
    })

    const clientArray = [];
    const statusArray = [];
    const platformArray = [];

    if (ds.user.accessibleClient !== undefined && ds.user.accessibleClient !== null) {
        if (clientArray.length === 0) {
            let clientIndex = 0;
            for (let key in ds.user.accessibleClient) {
                clientArray[clientIndex++] = {
                    value: ds.user.accessibleClient[key].clientKey,
                    text: ds.user.accessibleClient[key].clientName
                };
            }
        }
    }

    const [clientPlaceholder, setClientPlaceholder] = useState(querySearch.clientName ? querySearch.clientName : querySearch.clientKey ? _.find(clientArray, {value: querySearch.clientKey}).text : '');

    if (statusArray.length === 0) {
        Object.keys(AuthResult).map((key, index) => {
            statusArray[index] = {
                key: key,
                value: AuthResult[key],
                text: t(CommonCodeValue.translateKey + '.' + AuthResult[key])
            };
        })
    }

    if (platformArray.length === 0) {
        Object.keys(AuthPlatform).map((key, index) => {
            platformArray[index] = {
                key: key,
                value: AuthPlatform[key],
                text: t(CommonCodeValue.translateKey + '.' + AuthPlatform[key])
            };
        })
    }

    const selectOptionArray = [
        {label: 'UserPage.AuthHistory.Result', items: statusArray, value: status, onChange: (value) => setStatus(value)},
        {label: 'UserPage.AuthHistory.Platform', items: platformArray, value: platform, onChange: (value) => setPlatform(value)},
    ]

    const searchOptionArray = [
        {label: 'EventHistory.Table.UserKey', value: userKey, onChange: (value) => setUserKey(value)}
    ]

    if (pageType !== 'myHistory' && pageType !== 'all') {
        selectOptionArray.splice(0, 1);
        searchOptionArray.splice(0, 2);
    }

    if (pageType === 'myHistory') {
        searchOptionArray.splice(0, 1);
    }


    const fetchData = () => {
        if (pageType === 'myHistory') {
            api.getMyAuthHistoryList(query.current, page, pageSize, sort)
                .then((res) => {
                    setResponse(res);
                })
                .catch((err) => {
                    ds.showErrorAlert(api.getErrMsg(err.rtCode));
                })
        } else if (pageType === 'all') {
            api.getAllAuthHistory(query.current, page, pageSize, sort)
                .then((res) => {
                    setResponse(res);
                })
                .catch((err) => {
                    ds.showErrorAlert(api.getErrMsg(err.rtCode));
                })
        } else {
            api.getUserAuthHistoryList(props.params.clientKey, props.params.userKey, query.current, page, pageSize, sort)
                .then((res) => {
                    setResponse(res);
                })
                .catch((err) => {
                    ds.showErrorAlert(api.getErrMsg(err.rtCode));
                })
        }

    }

    const onSearch = () => {
        query.current = {
            clientKey:      isEmpty(clientKey),
            clientName:     isEmpty(clientName),
            status:         isEmpty(status),
            platform:       isEmpty(platform),
            userKey:        isEmpty(userKey),
            startDt:        isEmpty(startDt),
            endDt:          isEmpty(endDt)
        }

        if (startDt !== null && endDt !== null) page !== 0 ? setPage(0) : fetchData();
        if (startDt === null) setStartErr(true);
        if (endDt === null) setEndErr(true);
        addFilter(startDt, endDt, deleteDates,  clientPlaceholder, deleteClientName, selectOptionArray, searchOptionArray, setFilters);
        if(resetUpt) setResetUpt(false);
        if (pageType === 'myHistory' || pageType === 'all') {
            history.push({ search: "?" + api.encodeParams({...query.current, page: page, size: pageSize, sort: sort}, true) })
        } else {
            history.push({ search: "?" + api.encodeParams({clientKey: props.params.clientKey, userKey: props.params.userKey, ...query.current, page: page, size: pageSize, sort: sort }, true) })
        }
    }

    useEffect(() => {
        api.getHistoryValueList()
            .then((res) => {
                setDateRange(res.data.dateRange)
            })
            .catch((err) => {
                ds.showErrorAlert(api.getErrMsg(err.rtCode));
            })
        addFilter(startDt, endDt, deleteDates, clientPlaceholder, deleteClientName, selectOptionArray, searchOptionArray, setFilters);
    }, []);

    useEffect(() => {
        if (dateRange !== null) {
            if (query.current.startDt !== null && query.current.endDt !== null) fetchData();
            if (minDt === null) setMinDt(moment(today).subtract((dateRange - 1), 'd').format('YYYY-MM-DD'))
        }
    }, [page, pageSize, sort, dateRange]);

    useEffect(() => {
        if (resetUpt) onSearch()
    }, [resetUpt]);

    const onDeleteFilter = (idx, option) => {
        option.items ? option.onChange('ALL') : option.onChange('');
        let arr = _.cloneDeep(filters);
        arr.splice(idx, 1);
        setFilters(arr);
    }

    function resetDates() {
        setStartDt(todayStart);
        setEndDt(todayEnd);
        setMinDt(moment(today).subtract((dateRange - 1), 'd').format('YYYY-MM-DD'));
        setMaxDt(null);
    }

    function deleteDates() {
        setStartDt(null); setEndDt(null); setMinDt(null); setMaxDt(null)
    }

    const resetAll = () => {
        selectOptionArray.map((option) => option.onChange('ALL'));
        searchOptionArray.map((option) => option.onChange(''));
        resetDates();
        deleteClientName();
        setResetUpt(true)
    }

    const head = [[
        {content: ''},
        (pageType === 'myHistory' || pageType === 'all') && {content: t("EventHistory.Table.Client")},
        (pageType === 'all') && {content: t('ClientDetail.AuthHistory.UserId')},
        {content: t("UserPage.AuthHistory.Result")},
        {content: t("UserPage.AuthHistory.Platform")},
        {content: t('UserPage.AuthHistory.Content')},
        {content: t('UserPage.AuthHistory.Ip')},
        {content: <TableHeadSort sortType={sort} setSortType={setSort}/>}
    ]]

    const body = [];
    if (response !== null && response.data !== undefined) {

        let num = response.page.totalElements - (response.page.currentPage * pageSize);
        response.data.map((data, idx) => {

            let clientKey = data.clientKey && clientArray.length > 0 ? clientArray.find(c => c.value === data.clientKey) ? clientArray.find(c => c.value === data.clientKey).text : data.clientKey : '-';
            let userKey = data.userKey ? data.userKey : "-";
            let status = data.status ? t(CommonCodeValue.translateKey + '.' + data.status) : '-';
            let platform = data.platform ? t(CommonCodeValue.translateKey + '.' + data.platform) : '-';
            let content = data.content ? data.content : '-';
            let connectIp = data.connectIp ? data.connectIp : '-';
            let timestamp = data.regDt ? moment(data.regDt).utcOffset(8).format('YYYY-MM-DD HH:mm:ss')  : '-';

            body[idx] = [
                {id: 'index', content: num--},
                (pageType === 'myHistory' || pageType === 'all') && {id: 'client', content: clientKey},
                (pageType === 'all') && {id: 'user', content: userKey},
                {id: 'status', content: status},
                {id: 'platform', content: platform},
                {id: 'content', content: content},
                {id: 'ip', content: connectIp},
                {id: 'timestamp', content: timestamp},
                {id: 'seq', content: data.seq}
            ];

        });
    }

    const pageChangeEvent = (event, pageIndex) => {
        if (pageType === 'myHistory' || pageType === 'all') {
            history.push({ search: "?" + api.encodeParams({...query.current, page: pageIndex- 1, size: pageSize, sort: sort}, true) })
        } else {
            history.push({ search: "?" + api.encodeParams({clientKey: props.params.clientKey, userKey: props.params.userKey, ...query.current, page: pageIndex- 1, size: pageSize, sort: sort }, true) })
        }
        setPage(pageIndex- 1);
    };

    const onSubmitDate = (type, value) => {
        if (type === 'start') {
            setStartDt(value);
            setMaxDt(moment(value).add((dateRange - 1), 'd').format('YYYY-MM-DD'))
            if (startErr) setStartErr(false);
        }
        if (type === 'end') {
            setEndDt(value);
            setMinDt(moment(value).subtract((dateRange - 1), 'd').format('YYYY-MM-DD'))
            if (endErr) setEndErr(false);
        }
    }

    const onChangePageSize = (value) => {
        if (pageType === 'myHistory' || pageType === 'all') {
            history.push({ search: "?" + api.encodeParams({...query.current, page: page, size: value, sort: sort}, true) })
        } else {
            history.push({ search: "?" + api.encodeParams({clientKey: props.params.clientKey, userKey: props.params.userKey, ...query.current, page: page, size: value, sort: sort }, true) })
        }
        setPageSize(value);
    }

    const onChangeClientName = (value) => {
        setClientKey('ALL');
        setClientName(value);
        setClientPlaceholder(value);
    }

    const onSelectClientName = (value, text) => {
        setClientName('');
        setClientKey(value);
        setClientPlaceholder(text);
    }

    function deleteClientName() {
        setClientKey('ALL');
        setClientName('');
        setClientPlaceholder('')
    }

    return (
        <HistoryPage
            selectOptionArray={selectOptionArray}
            searchOptionArray={searchOptionArray}
            clientSearch={{clientPlaceholder, clientArray, onChangeClientName, onSelectClientName}}
            filters={filters}
            onSearch={onSearch}
            onReset={resetAll}
            onDeleteFilter={onDeleteFilter}
            startDt={startDt}
            endDt={endDt}
            startErr={startErr}
            endErr={endErr}
            minDt={minDt}
            maxDt={maxDt}
            onSubmitDate={onSubmitDate}
            table={{
                data: response,
                head: head,
                body: body,
                page: page,
                pageSize: pageSize,
                pageChangeEvent: pageChangeEvent,
                setPageSize: onChangePageSize
            }}
        />
    )
}