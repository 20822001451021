import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {Typography} from "@material-ui/core";
import moment from "moment";
import {Tooltip} from "@mui/material";
import {useHistory} from "react-router";
import api from "../../common/api";
import {CommonCodeValue, isCommonCode} from "../../common/types";
import {useStores} from "../../common/store";
import queryString from 'query-string';
import {addFilter, HistoryPage, isEmpty, TableHeadSort} from "./HistoryCommon";

export default function AgreementHistoryPage(props) {

    const {t} = useTranslation();
    const history = useHistory();
    const {ds} = useStores();
    const querySearch = queryString.parse(props.location.search);
    const { valueList, clientArray, dateRange } = props;

    const [category, setCategory] = useState(querySearch.category ? querySearch.category : 'ALL');
    const [clientName, setClientName] = useState(querySearch.clientKey ? querySearch.clientKey : 'ALL');
    const [valueName, setValueName] = useState(querySearch.name ? querySearch.name : 'ALL');
    const [eventType, setEventType] = useState(querySearch.eventType ? querySearch.eventType : 'ALL');

    const [eventTitle, setEventTitle] = useState(querySearch.agreementTitle ? querySearch.agreementTitle : '');
    const [clientNameSearch, setClientNameSearch] = useState(querySearch.clientName ? querySearch.clientName : '');
    const [changeValue, setChangeValue] = useState(querySearch.changeValue ? querySearch.changeValue : '');
    const [actionUser, setActionUser] = useState(querySearch.actionUserKey ? querySearch.actionUserKey : '');
    const [clientPlaceholder, setClientPlaceholder] = useState(querySearch.clientName ? querySearch.clientName : querySearch.clientKey ? _.find(clientArray, {value: querySearch.clientKey}).text : '');

    const [filters, setFilters] = useState([])
    const [page, setPage] = useState(querySearch.page ? parseInt(querySearch.page) : 0);
    const [pageSize, setPageSize] = useState(querySearch.size ? parseInt(querySearch.size)  : 5);
    const [sort, setSort] = useState(querySearch.sort ? querySearch.sort  : 'REG_DT,DESC');

    let today = moment(new Date()).format('YYYY-MM-DD');
    const todayStart = today + " 00:00";
    const todayEnd = today + " 23:59";
    const [minDt, setMinDt] = useState(null);
    const [maxDt, setMaxDt] = useState(null);
    const [startDt, setStartDt] = useState(querySearch.startDt ? querySearch.startDt : todayStart);
    const [endDt, setEndDt] = useState(querySearch.endDt ? querySearch.endDt : todayEnd);
    const [startErr, setStartErr] = useState(false);
    const [endErr, setEndErr] = useState(false);
    const [resetUpt, setResetUpt] = useState(false);

    const [response, setResponse] = useState(null);

    const query = useRef({
        category:       isEmpty(category),
        clientName:     isEmpty(clientNameSearch),
        clientKey:      isEmpty(clientName),
        eventType:      isEmpty(eventType),
        name:           isEmpty(valueName),
        agreementTitle: isEmpty(eventTitle),
        changeValue:    isEmpty(changeValue),
        actionUserKey:  isEmpty(actionUser),
        startDt:        isEmpty(startDt),
        endDt:          isEmpty(endDt)
    })

    const categoryArray = [
        {value: 'CMMHSC001', text: t(CommonCodeValue.translateKey + '.CMMHSC001')},
        {value: 'CMMHSC002', text: t(CommonCodeValue.translateKey + '.CMMHSC002'), disabled: valueName !== 'ALL'}
    ]

    const eventTypeArray = [
        {value: 'CMMACT001', text: t(CommonCodeValue.translateKey + '.CMMACT001')},
        {value: 'CMMACT002', text: t(CommonCodeValue.translateKey + '.CMMACT002')},
        {value: 'CMMACT003', text: t(CommonCodeValue.translateKey + '.CMMACT003'), disabled: valueName !== 'ALL' || changeValue !== ''}
    ]

    const valueNameArray = [];
    if (valueNameArray.length === 0 && valueList.length > 0) {
        valueList.find(item => item.category === 'CMMHSC001').valueNames?.map((i) => {
            valueNameArray.push({value: i, text: t('EventHistory.ValueName.' + i)})
        });
        valueList.find(item => item.category === 'CMMHSC002').valueNames?.map((i) => {
            valueNameArray.push({value: i, text: t('EventHistory.ValueName.' + i)})
        });
    }

    const selectOptionArray = [
        {label: 'EventHistory.Table.Category', items: categoryArray, value: category, onChange: (value) => setCategory(value)},
        {label: 'EventHistory.Table.EventType', items: eventTypeArray, value: eventType, onChange: (value) => setEventType(value)},
        {label: 'EventHistory.Table.ValueName', items: valueNameArray, value: valueName, onChange: (value) => setValueName(value), disabled: category === 'CMMHSC002' || eventType === 'CMMACT003'}
    ]

    const searchOptionArray = [
        {label: 'EventHistory.Table.Title', value: eventTitle, onChange: (value) => setEventTitle(value)},
        {label: 'EventHistory.Table.ChangeValue', value: changeValue, onChange: (value) => setChangeValue(value), disabled: category === 'CMMHSC002' || eventType === 'CMMACT003'},
        {label: 'EventHistory.ActionUserKey', value: actionUser, onChange: (value) => setActionUser(value)}
    ]

    const fetchData = () => {
        api.getAgreementHistory(query.current, page, pageSize, sort)
            .then((res) => {
                setResponse(res);
            })
            .catch((err) => {
                ds.showErrorAlert(api.getErrMsg(err.rtCode));
            })
    }

    const onSearch = () => {

        query.current = {
            category:       isEmpty(category),
            clientName:     isEmpty(clientNameSearch),
            clientKey:      isEmpty(clientName),
            eventType:      isEmpty(eventType),
            name:           isEmpty(valueName),
            agreementTitle: isEmpty(eventTitle),
            changeValue:    isEmpty(changeValue),
            actionUserKey:  isEmpty(actionUser),
            startDt:        isEmpty(startDt),
            endDt:          isEmpty(endDt)
        }

        if (startDt !== null && endDt !== null) page !== 0 ? setPage(0) : fetchData();
        if (startDt === null) setStartErr(true);
        if (endDt === null) setEndErr(true);
        addFilter(startDt, endDt, deleteDates, clientPlaceholder, deleteClientName, selectOptionArray, searchOptionArray, setFilters);
        if(resetUpt) setResetUpt(false);
        history.push({ search: "?" + api.encodeParams({...query.current, page: page, size: pageSize, sort: sort}, true) })
    }

    useEffect(() => {
        if (dateRange !== null) {
            if (query.current.startDt !== null && query.current.endDt !== null) fetchData();
            if (minDt === null) setMinDt(moment(today).subtract((dateRange - 1), 'd').format('YYYY-MM-DD'))
        }
    }, [page, pageSize, sort, dateRange]);

    useEffect(() => {
        if (valueNameArray.length > 0) addFilter(startDt, endDt, deleteDates, clientPlaceholder, deleteClientName, selectOptionArray, searchOptionArray, setFilters);
    }, [valueList]);

    useEffect(() => {
        if (resetUpt) onSearch()
    }, [resetUpt]);

    const onDeleteFilter = (idx, option) => {
        option.items ? option.onChange('ALL') : option.onChange('');
        let arr = _.cloneDeep(filters);
        arr.splice(idx, 1);
        setFilters(arr);
    }

    function resetDates() {
        setStartDt(todayStart);
        setEndDt(todayEnd);
        setMinDt(moment(today).subtract((dateRange - 1), 'd').format('YYYY-MM-DD'));
        setMaxDt(null);
    }

    function deleteDates() {
        setStartDt(null); setEndDt(null); setMinDt(null); setMaxDt(null)
    }

    const resetAll = () => {
        selectOptionArray.map((option) => option.onChange('ALL'));
        searchOptionArray.map((option) => option.onChange(''));
        resetDates();
        deleteClientName();
        setResetUpt(true)
    }

    const rowClickEvent = (row) => (event) => {
        history.push({
            pathname: '/admin/event/detail',
            search: "?" + api.encodeParams({seq: row[body[0].length-1].content, category: row[1].code}),
            state: api.encodeParams({historyLabel: "EventHistory.AgreementHistory", historyUrl: "/admin/events/agreementHistory"})
        })
    }

    const head = [[
        {content: ''},
        {content: t("EventHistory.Table.Category")},
        {content: t("EventHistory.Table.Title")},
        {content: t("EventHistory.Table.Client")},
        {content: t("EventHistory.Table.EventType")},
        query.current.eventType !== 'CMMACT003' && {content: t("EventHistory.Table.ValueName")},
        query.current.eventType !== 'CMMACT003' && {content: t("EventHistory.Table.ChangeValue")},
        {content: t("EventHistory.ActionUserKey")},
        {content: t("EventHistory.Table.IP")},
        {content: <TableHeadSort sortType={sort} setSortType={setSort}/>}
    ]]

    const body = [];
    if (response !== null && response.data !== undefined) {

        let num = response.page.totalElements - (response.page.currentPage * pageSize);
        response.data.map((data, idx) => {
            let category = data.category ? t(CommonCodeValue.translateKey + '.' + data.category) : '-';
            let clientName = data.clientKey && clientArray.find(x => x.value === data.clientKey) ? clientArray.find(c => c.value === data.clientKey).text : "-";

            let title = data.agreementTitle ? data.agreementTitle : '-';
            if (title.length > 18) title = <Tooltip title={title} arrow><Typography>{title}</Typography></Tooltip>

            let eventType = data.eventType ? t(CommonCodeValue.translateKey + '.' + data.eventType) : '-';

            let valueName = data.name ? data.name : '-';
            if (valueNameArray.find(x => x.value === data.name) !== undefined) valueName = valueNameArray.find(x => x.value === data.name).text;

            let changeValue = data.changeValue ? <Typography dangerouslySetInnerHTML={{__html: data.changeValue}}/> : '-';
            if (data.changeValue && isCommonCode(data.changeValue)) changeValue = data.changeValue + "\n(" + t(CommonCodeValue.translateKey + '.' + data.changeValue) + ")";

            let actionUserKey = data.actionUserKey ? data.actionUserKey : '-';
            let actionIP = data.actionIp ? data.actionIp : '-';
            let timestamp = data.regDt ? moment(data.regDt).utcOffset(8).format('YYYY-MM-DD HH:mm:ss')  : '-';

            body[idx] = [
                {id: 'index', content: num--},
                {id: 'type', content: category, code: data.category},
                {id: 'title', content: title},
                {id: 'client', content: clientName},
                {id: 'eventType', content: eventType},
                query.current.eventType !== 'CMMACT003' && {id: 'valueName', content: valueName},
                query.current.eventType !== 'CMMACT003' && {id: 'changeValue', content: changeValue},
                {id: 'userKey', content: actionUserKey},
                {id: 'ip', content: actionIP},
                {id: 'timestamp', content: timestamp},
                {id: 'seq', content: data.seq}
            ];

        });

        if (filters.findIndex(item => item.value === t('CommonCode.CMMHSC001')) !== -1) {
            body.map(item => item.splice(item.findIndex(i => i.id === 'client'),1))
            let idx = head[0].findIndex(item => item.content === t("EventHistory.Table.Client"));
            if (idx !== -1) head[0].splice(idx, 1)
        }
        if (filters.findIndex(item => item.value === t('CommonCode.CMMHSC002')) !== -1) {
            body.map(item => item.splice(item.findIndex(i => i.id === 'valueName'), 1))
            body.map(item => item.splice(item.findIndex(i => i.id === 'changeValue'), 1))
            let idxValue = head[0].findIndex(item => item.content === t("EventHistory.Table.ValueName"));
            if (idxValue !== -1) head[0].splice(idxValue, 1)
            let idxChange = head[0].findIndex(item => item.content === t("EventHistory.Table.ChangeValue"));
            if (idxChange !== -1) head[0].splice(idxChange, 1)
        }
    }

    const pageChangeEvent = (event, pageIndex) => {
        history.push({ search: "?" + api.encodeParams({...query.current, page: pageIndex - 1, size: pageSize, sort: sort}, true) })
        setPage(pageIndex- 1);
    };

    const onSubmitDate = (type, value) => {
        if (type === 'start') {
            setStartDt(value);
            setMaxDt(moment(value).add((dateRange - 1), 'd').format('YYYY-MM-DD'))
            if (startErr) setStartErr(false);
        }
        if (type === 'end') {
            setEndDt(value);
            setMinDt(moment(value).subtract((dateRange - 1), 'd').format('YYYY-MM-DD'))
            if (endErr) setEndErr(false);
        }
    }

    const onChangePageSize = (value) => {
        history.push({ search: "?" + api.encodeParams({...query.current, page: page, size: value, sort: sort}, true) })
        setPageSize(value);
    }

    const onChangeClientName = (value) => {
        setClientName('ALL');
        setClientNameSearch(value);
        setClientPlaceholder(value);
    }

    const onSelectClientName = (value, text) => {
        setClientNameSearch('');
        setClientName(value);
        setClientPlaceholder(text);
    }

    function deleteClientName() {
        setClientName('ALL');
        setClientNameSearch('');
        setClientPlaceholder('')
    }

    return (
        <HistoryPage
            selectOptionArray={selectOptionArray}
            searchOptionArray={searchOptionArray}
            clientSearch={{clientPlaceholder, clientArray, onChangeClientName, onSelectClientName}}
            filters={filters}
            onSearch={onSearch}
            onReset={resetAll}
            onDeleteFilter={onDeleteFilter}
            startDt={startDt}
            endDt={endDt}
            startErr={startErr}
            endErr={endErr}
            minDt={minDt}
            maxDt={maxDt}
            onSubmitDate={onSubmitDate}
            table={{
                data: response,
                head: head,
                body: body,
                page: page,
                pageSize: pageSize,
                pageChangeEvent: pageChangeEvent,
                rowClickEvent: rowClickEvent,
                setPageSize: onChangePageSize
            }}
        />
    )
}